<script lang="ts" setup generic="T extends GenericObject = GenericObject">
import { computed } from 'vue';
import type { GenericObject } from '../../../shared/types';
import type { UiDataTableColumn } from '../interfaces';
import { useTableContext } from '../shared';

interface Props {
  column?: UiDataTableColumn<T>;
}

const props = defineProps<Props>();

const {
  onResizeHandleMouseEnter,
  onResizeHandleMouseLeave,
  onResizeHandleMouseDown,
  onResizeHandleDoubleClick,
  resizingColumnKey,
  regularColumns,
} = useTableContext<T>();

const last = computed(() => {
  if (!props.column) {
    return false;
  }

  const index = regularColumns.value.findIndex(({ key }) => key === props.column?.key);

  return index === regularColumns.value.length - 1;
});

const handleActive = computed(() => props.column && resizingColumnKey.value === props.column.key);
</script>

<template>
  <div :class="[$style.root, { [$style.last]: last }]">
    <slot />
    <div
      v-if="props.column && props.column.resizable !== false && !props.column.frozen"
      :class="[$style.handle, { [$style.handleActive]: handleActive }]"
      @mouseenter="onResizeHandleMouseEnter(props.column.key)"
      @mouseleave="onResizeHandleMouseLeave()"
      @mousedown="onResizeHandleMouseDown($event, props.column.key)"
      @dblclick="onResizeHandleDoubleClick($event, props.column.key)"
    />
  </div>
</template>

<style lang="scss" module>
@use '../../../styles/colors';
@use '../../../styles/shared';
@use '../../../styles/typography';
@use '../shared' as tableSharedStyles;

.root {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: auto;
  flex: 0 0 auto;
  font-family: typography.$font-family-primary;
  font-size: 13px;
  line-height: 16px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-right: 1px solid tableSharedStyles.$border-color;
    z-index: tableSharedStyles.$z-index-border;
    pointer-events: none;
  }
}

.handle {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  width: 12px;
  cursor: col-resize;
  z-index: tableSharedStyles.$z-index-resize-handle;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5px;
    width: 2px;
  }
}

.handleActive::before {
  background-color: #085cd9;
  transition-property: background-color;
  transition-timing-function: linear;
  transition-duration: 0.1s;
  transition-delay: 0.1s;
}

.last .handle {
  right: 0;

  &::before {
    right: 0;
  }
}
</style>
