<script lang="ts" setup>
import {
  computed,
  watch,
  nextTick,
  shallowRef,
  type VNodeProps,
  type VNode,
  type ComponentPublicInstance,
} from 'vue';
import { unrefElement } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useFocusZone } from '../../composables';
import { getClosestFocusable } from '../../utils';
import { ObScrollableContainer } from '../scrollable-container';
import { ObActionListContextProvider } from '../action-list';
import { ObHostedDropdown } from '../hosted-dropdown';

interface Props {
  overlay?: boolean;
  open?: boolean;
}

defineOptions({
  inheritAttrs: false, // TODO: attach $attrs
});

const { overlay = false } = defineProps<Props>();

defineSlots<{
  default?: () => VNode;
  host?: (props: { open: boolean; hostProps: VNodeProps }) => VNode;
}>();

const hostRef = shallowRef<HTMLElement | null>(null);
const containerRef = shallowRef<HTMLElement | null>(null);

const open = defineModel<boolean>('open', { default: false });

const { focusFirst, focusLast } = useFocusZone({
  container: containerRef,
});

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(containerRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  returnFocusOnDeactivate: false,
  fallbackFocus: () => {
    return unrefElement(containerRef) ?? document.body;
  },
});

watch(
  open,
  (value) => {
    if (value) {
      nextTick(() => {
        activateTrap();
      });
      return;
    }

    deactivateTrap();
  },
  { immediate: true },
);

function onEsc(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

function onTab(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();

  open.value = false;

  nextTick(() => {
    if (!hostRef.value) {
      return;
    }

    const target =
      getClosestFocusable({
        initial: hostRef.value,
        root: document.documentElement,
        previous: event.shiftKey,
      }) ?? hostRef.value;

    target?.focus();
  });
}

function onHostClick(event: MouseEvent) {
  event.preventDefault();
  open.value = !open.value;
}

function onHostKeydown(event: KeyboardEvent) {
  if (event.key === 'ArrowDown') {
    event.preventDefault();
    open.value = true;
    setTimeout(() => {
      focusFirst();
    });
    return;
  }

  if (event.key === 'ArrowUp') {
    event.preventDefault();
    open.value = true;
    setTimeout(() => {
      focusLast();
    });
    return;
  }
}

function onItemSelect() {
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

const hostProps = computed(() => ({
  onClick: onHostClick,
  onKeydown: onHostKeydown,
  'aria-haspopup': true,
  'aria-expanded': open.value,
}));
</script>

<template>
  <ObHostedDropdown
    v-model:active="open"
    :overlay
    tabindex="-1"
    role="none"
    shift
    flip
    :padding="5"
    :size="{
      apply({ availableWidth, availableHeight, elements }) {
        Object.assign(elements.floating.style, {
          maxWidth: `${Math.max(0, availableWidth)}px`,
          maxHeight: `${Math.max(0, availableHeight)}px`,
        });
      },
    }"
    @keydown.esc="onEsc"
    @keydown.tab="onTab"
    @click-outside="open = false"
  >
    <template #host="{ hostProps: dropdownHostProps }">
      <slot
        name="host"
        v-bind="{
          open,
          hostProps: {
            ...dropdownHostProps,
            ...hostProps,
            ref(el: Element | ComponentPublicInstance | null) {
              dropdownHostProps.ref(el);
              hostRef = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
            },
          },
        }"
      />
    </template>
    <ObScrollableContainer ref="containerRef" light>
      <ObActionListContextProvider
        :on-after-select="onItemSelect"
        list-role="menu"
        item-role="menuitem"
      >
        <slot />
      </ObActionListContextProvider>
    </ObScrollableContainer>
  </ObHostedDropdown>
</template>
