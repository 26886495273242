import type { InjectionKey, Ref, ComputedRef } from 'vue';
import { injectStrict } from '../../utils';
import type { GenericObject } from '../../shared/types';
import type { UiDataTableSorting, UiDataTableColumn } from './interfaces';
interface CellContext {
  align: 'start' | 'end' | 'center';
  monospace: boolean;
  value: string;
  rawValue: any;
}

export const CELL_CONTEXT: InjectionKey<CellContext> = Symbol(__DEV__ ? 'CellContext' : '');

export interface TableContext<T extends GenericObject = GenericObject> {
  resizingColumnKey: Ref<string | null>;
  pinnedColumnsKeys: Ref<string[]>;
  sorting: Ref<UiDataTableSorting>;
  setSorting: (value: UiDataTableSorting) => void;
  hideColumn: (key: string) => void;
  onResizeHandleMouseEnter: (key: string) => void;
  onResizeHandleMouseLeave: () => void;
  onResizeHandleMouseDown: (event: MouseEvent, key: string) => void;
  onResizeHandleDoubleClick: (event: MouseEvent, key: string) => void;
  startDragging: (event: MouseEvent, key: string) => void;
  draggedColumnIndex: ComputedRef<number>;
  targetColumnIndex: ComputedRef<number>;
  draggedColumnKey: Ref<string | null>;
  targetColumnKey: Ref<string | null>;
  regularColumns: ComputedRef<UiDataTableColumn<T>[]>;
  pinnedColumns: ComputedRef<UiDataTableColumn<T>[]>;
  pinColumn: (key: string) => void;
  unpinColumn: (key: string) => void;
}

export const TABLE_CONTEXT = Symbol(__DEV__ ? 'TableContext' : '') as InjectionKey<
  TableContext<any>
>;

export function useTableContext<T extends GenericObject = GenericObject>() {
  return injectStrict<TableContext<T>>(TABLE_CONTEXT);
}
