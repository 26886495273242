<script lang="ts" setup generic="O = any, V = any">
import {
  ref,
  computed,
  nextTick,
  shallowRef,
  useId,
  watch,
  useTemplateRef,
  watchEffect,
  type VNode,
  type ComponentPublicInstance,
} from 'vue';
import { unrefElement, useFocus } from '@vueuse/core';
import { IconChevronDown } from '@tabler/icons-vue';
import { useFocusZone, useOptionsList, type OptionsListItem } from '../../composables';
import { areValuesEqual } from '../../utils';
import { Keys } from '../../shared/enums';
import type { SizeS, SizeM, SizeL, OptionsListProps } from '../../shared/types';
import { ObActionListContextProvider, ObActionList, ObActionListItem } from '../action-list';
import { ObScrollableContainer } from '../scrollable-container';
import { ObPrimitiveInput } from '../primitive-input';
import { ObSpinner } from '../spinner';
import { ObTag } from '../tag';
import { ObHostedDropdown } from '../hosted-dropdown';

type Props = OptionsListProps<O, V> & {
  id?: string;
  invalid?: boolean;
  disabled?: boolean;
  modelValue?: V[];
  placeholder?: string;
  size?: SizeS | SizeM | SizeL;
  externalSearch?: boolean;
  optionsLoading?: boolean;
  open?: boolean;
};

defineOptions({
  inheritAttrs: false,
});

const {
  id,
  disabled = false,
  invalid = false,
  size = 'm',
  options = [],
  optionDisabled,
  optionLabel,
  optionValue,
  loadMissingOptions,
  trackValueBy,
  optionsLoading = false,
  externalSearch = false,
} = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: V | null];
  search: [search: string];
}>();

defineSlots<{
  default?: (props: {
    optionsList: OptionsListItem<O, V>[];
    toggleOption: (option: OptionsListItem<O, V>) => void;
    selectedOptions: OptionsListItem<O, V>[] | null;
  }) => VNode;
  noOptions?: (props: { search: string }) => VNode;
}>();

const hostRef = shallowRef<HTMLElement | null>();
const inputRef = shallowRef<HTMLInputElement | null>();
const containerRef = useTemplateRef<InstanceType<typeof ObHostedDropdown>>('container');
const containerElement = computed(() => containerRef.value?.$el as HTMLDivElement); // TODO: why defineExpose ignores real $el type?

const open = defineModel<boolean>('open', { default: false });

const listId = useId();
const { focused } = useFocus(inputRef);

let activeDescendant: HTMLElement | undefined;

const {
  focusFirst: focusFirstOption,
  focusLast: focusLastOption,
  focus: focusOption,
} = useFocusZone({
  container: containerElement,
  activeDescendantControl: inputRef,
  onActiveDescendantChanged: (current, previous, directlyActivated) => {
    activeDescendant = current;

    if (directlyActivated) {
      activeDescendant?.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  },
});

const modelValue = defineModel<V[]>({ default: [] });

const search = ref('');

watch(search, (value) => {
  emit('search', value);
});

watchEffect(() => {
  if (open.value) {
    focusInput();
  }
});

const { filteredOptionsList: optionsList, selectedOptions } = useOptionsList<O, V>({
  options: computed(() => options), // reactivity
  optionDisabled,
  optionLabel,
  optionValue,
  loadMissingOptions,
  trackValueBy,
  selectionMode: 'multiple',
  selected: modelValue,
  search: computed(() => {
    if (externalSearch) {
      return '';
    }

    return search.value;
  }),
});

function toggleOption(option: OptionsListItem<O, V>) {
  if (option.selected) {
    modelValue.value = modelValue.value.filter(
      (item) => !areValuesEqual<V>(item, option.value, trackValueBy),
    );
    return;
  }

  modelValue.value = [...modelValue.value, option.value];
}

function focusInput() {
  inputRef.value?.focus({ preventScroll: true });
}

function onClick(event: MouseEvent) {
  if (disabled) {
    return;
  }

  if (event.target === unrefElement(inputRef)) {
    open.value = true;
  } else {
    event.preventDefault();
    open.value = !open.value;
    focusInput();
  }

  if (open.value) {
    setTimeout(() => {
      const selected = containerElement.value?.querySelector<HTMLElement>('[aria-selected="true"]');
      if (selected) {
        focusOption(selected);
      }
    });
  }
}

const EDITING_KEYS: string[] = [Keys.Space, Keys.Backspace, Keys.Delete];

function onInputKeydown(event: KeyboardEvent) {
  if (event.key === Keys.Enter) {
    if (activeDescendant) {
      event.preventDefault();
      event.stopImmediatePropagation();

      // Forward Enter key press to active descendant so that item gets activated
      const activeDescendantEvent = new KeyboardEvent(event.type, event);
      activeDescendant.dispatchEvent(activeDescendantEvent);
    }
    return;
  }

  if (event.key === Keys.Backspace) {
    if (!search.value && selectedOptions.value.length) {
      event.preventDefault();
      const lastOption = selectedOptions.value[selectedOptions.value.length - 1];
      toggleOption(lastOption);

      search.value = lastOption.label;
      inputRef.value?.select();
    }
    return;
  }

  if (event.key === Keys.Escape) {
    event.preventDefault();
    event.stopPropagation();
    open.value = false;
    search.value = '';
    return;
  }

  if (open.value) {
    return;
  }

  if (event.key === Keys.ArrowDown || event.key === Keys.ArrowUp) {
    event.preventDefault();
    event.stopPropagation();
    open.value = true;
    nextTick(() => {
      if (event.key === Keys.ArrowDown) {
        focusFirstOption(true);
        return;
      }

      focusLastOption(true);
    });

    return;
  }

  if (event.key.length === 1 || EDITING_KEYS.includes(event.key)) {
    open.value = true;
  }
}

function onBlur(event: FocusEvent) {
  const relatedTarget = event.relatedTarget as HTMLElement;

  const hostElement = unrefElement(hostRef);

  if (hostElement === relatedTarget || hostElement?.contains(relatedTarget)) {
    return;
  }

  if (containerElement.value === relatedTarget || containerElement.value?.contains(relatedTarget)) {
    return;
  }

  open.value = false;
  search.value = '';
}

function onInput() {
  nextTick(() => {
    focusFirstOption();
  });
}

const { focus, focusableElements } = useFocusZone({
  container: hostRef,
  focusOutBehavior: 'wrap',
  bindKeys: ['ArrowLeft', 'ArrowRight'],
  focusableElementFilter(element) {
    return !element.getAttributeNames().includes('aria-hidden');
  },
  focusInStrategy(previousFocusedElement) {
    if (!previousFocusedElement) {
      return unrefElement(inputRef);
    }

    return previousFocusedElement;
  },
});

function onTagKeydown(event: KeyboardEvent) {
  if (event.key === Keys.Tab) {
    event.preventDefault();
    event.stopPropagation();
    focusInput();
    return;
  }

  if (event.key === Keys.Escape || event.key === Keys.ArrowUp || event.key === Keys.ArrowDown) {
    event.preventDefault();
    event.stopPropagation();
    focusInput();
    return;
  }
}

function onTagClick(event: MouseEvent) {
  event.preventDefault();
  event.stopPropagation();
}

function onTagRemove(index: number) {
  toggleOption(selectedOptions.value[index]);

  // Keep focus in the safe place while tags list re-renders...
  if (inputRef.value) {
    focus(inputRef.value);
  }

  // ...and move focus to the next tag after removed one
  nextTick(() => {
    if (focusableElements[index] && focusableElements[index] !== inputRef.value) {
      focus(focusableElements[index]);
    }
  });
}
</script>

<template>
  <ObHostedDropdown
    ref="container"
    v-model:active="open"
    tabindex="-1"
    role="dialog"
    shift
    flip
    :padding="5"
    :size="{
      apply({ elements, availableHeight }) {
        const { width } = elements.reference.getBoundingClientRect();
        Object.assign(elements.floating.style, {
          width: `${width}px`,
          maxHeight: `${Math.max(0, availableHeight)}px`,
        });
      },
    }"
    @click-outside="open = false"
  >
    <template #host="{ hostProps }">
      <ObPrimitiveInput
        v-bind="hostProps"
        :ref="
          (el: Element | ComponentPublicInstance | null) => {
            hostProps.ref(el);
            hostRef = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
          }
        "
        :size
        :invalid
        :disabled
        :focused
        tabindex="-1"
        @focus="focusInput()"
        @click="onClick"
        @focusout="onBlur"
      >
        <div :class="$style.valueContainer">
          <ObTag
            v-for="(option, i) in selectedOptions"
            :key="i"
            removable
            tabindex="0"
            @remove="onTagRemove(i)"
            @click="onTagClick"
            @keydown="onTagKeydown"
          >
            {{ option.label }}
          </ObTag>
          <div :class="$style.inputContainer">
            <input
              :id
              ref="inputRef"
              v-model="search"
              type="text"
              :class="$style.input"
              :placeholder="placeholder"
              :disabled
              aria-autocomplete="list"
              :aria-controls="listId"
              :aria-expanded="open"
              aria-haspopup="listbox"
              :aria-owns="listId"
              autocomplete="off"
              role="combobox"
              @keydown="onInputKeydown"
              @input="onInput"
            />
          </div>
        </div>
        <template #icon>
          <span :class="[$style.arrow, { [$style.arrowRotated]: open }]">
            <IconChevronDown />
          </span>
        </template>
      </ObPrimitiveInput>
    </template>
    <ObScrollableContainer light>
      <div v-if="optionsLoading" :class="$style.noData">
        <ObSpinner size="48px" />
        Loading
      </div>
      <div v-else-if="!optionsList.length" :class="$style.noData">
        <slot name="noOptions" v-bind="{ search }">
          {{ search ? `No items found for '${search}'` : 'No items found' }}
        </slot>
      </div>
      <ObActionListContextProvider
        v-else
        list-role="listbox"
        item-role="option"
        selection-mode="multiple"
      >
        <ObActionList :id="listId" compact>
          <slot
            v-bind="{
              optionsList,
              selectedOptions,
              toggleOption,
            }"
          >
            <ObActionListItem
              v-for="option in optionsList"
              :key="option.label"
              :selected="option.selected"
              :disabled="option.disabled"
              @select="toggleOption(option)"
            >
              {{ option.label }}
            </ObActionListItem>
          </slot>
        </ObActionList>
      </ObActionListContextProvider>
    </ObScrollableContainer>
  </ObHostedDropdown>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.valueContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  gap: 6px;
  padding: 5px 12px;
  box-sizing: border-box;
}

.inputContainer {
  order: 1;
  flex-grow: 1;
}

.input {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  text-align: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  border-radius: inherit;
  background: none;
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  min-height: 22px; // Tag height

  &::placeholder {
    color: colors.$surface-40;
    white-space: pre;
  }
}

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 4px;
  padding: 48px 8px;
}

.arrow {
  color: colors.$surface-40;
  display: flex;
  font-size: 24px;
  width: 1em;
  height: 1em;
  margin-left: 12px;
  transition: transform 0.2s ease-in-out;
}

.arrowRotated {
  transform: rotate(180deg);
}
</style>
