export function resolveI18n<
  TConfig extends Record<string, string | ((...params: any[]) => string)>,
  K extends keyof TConfig,
  T = TConfig[K],
>(
  config: TConfig,
  key: K,
  ...args: T extends (...params: infer P) => string ? P : never[]
): string {
  const value = config[key];

  if (typeof value === 'function') {
    return value(...args);
  }

  return value as string;
}
