<script lang="ts" setup>
import {
  ref,
  withKeys,
  computed,
  watch,
  shallowRef,
  useId,
  type VNodeProps,
  type VNode,
  type CSSProperties,
} from 'vue';
import { unrefElement } from '@vueuse/core';
import {
  useFloating,
  arrow,
  offset,
  inline,
  flip,
  autoUpdate,
  hide,
  shift,
} from '@floating-ui/vue';
import type { Placement, Middleware, ClientRectObject, ReferenceElement } from '@floating-ui/vue';
import { useFocusVisible, useZIndex } from '../../composables';
import { ObUnmountableTeleportWithTransition } from '../internal/unmountable-teleport-with-transition';

// TODO:  'toggletip' mode
// https://carbondesignsystem.com/components/toggletip/usage/
// https://web.dev/building-a-tooltip-component/

// TODO: optional disable hover/focus

interface Props {
  activateDelay?: number;
  active?: boolean;
  content?: string;
  deactivateDelay?: number;
  disableAnimation?: boolean;
  disabled?: boolean;
  inline?: boolean;
  maxWidth?: CSSStyleDeclaration['maxWidth'];
  minWidth?: CSSStyleDeclaration['minWidth'];
  placement?: Placement;
  virtualHost?: ClientRectObject;
  virtualHostContext?: Element;
}

const props = withDefaults(defineProps<Props>(), {
  activateDelay: 0,
  active: false,
  content: undefined,
  deactivateDelay: 0,
  disableAnimation: false,
  disabled: false,
  inline: false,
  maxWidth: undefined,
  minWidth: undefined,
  placement: 'top',
  virtualHost: undefined,
  virtualHostContext: undefined,
});

const emit = defineEmits<{
  'update:active': [active: boolean];
  activated: [];
  deactivated: [];
}>();

defineSlots<{
  default?: () => VNode;
  host?: (props: { active: boolean; hostProps: VNodeProps }) => VNode;
}>();

const active = defineModel<boolean>('active', { default: false });

function activate() {
  active.value = true;
  emit('activated');
}

function deactivate() {
  active.value = false;
  emit('deactivated');
}

const { zIndex } = useZIndex({ active });
const id = useId();

const hostElement = ref();
const floatingElement = ref<HTMLElement>();
const arrowEl = ref<HTMLElement>();

const middleware = shallowRef<Middleware[]>([]);

watch(
  [() => props.inline],
  () => {
    const _middleware = [offset(() => (arrowEl.value ? 8 : 0))];

    if (!props.virtualHost && props.inline) {
      _middleware.push(inline());
    }

    _middleware.push(flip());
    _middleware.push(
      shift({
        padding: 8,
      }),
    );
    _middleware.push(hide());
    _middleware.push(
      arrow({
        element: arrowEl,
        padding: 8,
      }),
    );

    middleware.value = _middleware;
  },
  { immediate: true },
);

const reference = computed<ReferenceElement | undefined>(() => {
  const { virtualHost } = props;

  if (virtualHost) {
    return {
      getBoundingClientRect() {
        return virtualHost;
      },
      contextElement: props.virtualHostContext,
    };
  }

  return hostElement.value;
});

const { placement, middlewareData, floatingStyles } = useFloating(reference, floatingElement, {
  placement: computed(() => props.placement),
  middleware,
  whileElementsMounted: autoUpdate,
  open: active,
  transform: false,
});

const basePlacement = computed(() => placement.value.split('-')[0]);

const floatingElementStyle = computed<CSSProperties>(() => ({
  ...floatingStyles.value,
  zIndex: zIndex.value,
  maxWidth: props.maxWidth,
  minWidth: props.minWidth,
  visibility: middlewareData.value.hide?.referenceHidden ? 'hidden' : 'visible',
}));

const arrowElementStyle = computed<CSSProperties>(() => {
  if (!middlewareData.value.arrow) {
    return {};
  }

  const { x, y } = middlewareData.value.arrow;

  return {
    position: 'absolute',
    left: x ? `${x}px` : undefined,
    top: y ? `${y}px` : undefined,
  };
});

const focusVisible = useFocusVisible();
const focused = ref(false);

let activateTimeout: ReturnType<typeof setTimeout>;
let deactivateTimeout: ReturnType<typeof setTimeout>;

function activateWithDelay() {
  clearTimeout(activateTimeout);
  clearTimeout(deactivateTimeout);

  if (props.activateDelay > 0) {
    activateTimeout = setTimeout(() => activate(), props.activateDelay);
    return;
  }

  activate();
}

function deactivateWithDelay() {
  clearTimeout(activateTimeout);
  clearTimeout(deactivateTimeout);

  if (props.deactivateDelay > 0) {
    deactivateTimeout = setTimeout(() => deactivate(), props.activateDelay);
    return;
  }

  deactivate();
}

function onEsc(event: KeyboardEvent) {
  if (!active.value) {
    return;
  }
  event.stopPropagation();
  event.preventDefault();
  deactivate();
}

function onFocus() {
  focused.value = true;

  if (!focusVisible.value) {
    return;
  }

  activateWithDelay();
}

function onBlur() {
  focused.value = false;

  deactivateWithDelay();
}

function onMouseenter() {
  if (focused.value) {
    return;
  }

  activateWithDelay();
}

function onMouseleave() {
  if (focused.value) {
    return;
  }

  deactivateWithDelay();
}

// Activate tooltip if first focused with tab element is tooltip host
watch(focusVisible, (value) => {
  if (value && focused.value && !active.value) {
    activate();
  }
});

const hostProps = computed(() => {
  if (props.disabled) {
    return {};
  }

  const result = {
    // TODO: fix typing issue
    ref: (el: any) => {
      hostElement.value = unrefElement(el);
    },
    'aria-describedby': id,
    onMouseenter,
    onMouseleave,
    onFocus,
    onBlur,
    onKeydown: withKeys(onEsc, ['esc']),
  };

  return result;
});
</script>

<template>
  <slot name="host" v-bind="{ active, hostProps }" />
  <ObUnmountableTeleportWithTransition
    :active
    mode="in-out"
    :enter-from-class="$style.enterFrom"
    :enter-active-class="$style.enterActive"
    :leave-active-class="$style.leaveActive"
    :leave-to-class="$style.leaveTo"
  >
    <div
      v-if="active"
      :id="id"
      ref="floatingElement"
      :style="floatingElementStyle"
      :class="[$style.root, { [$style.animationDisabled]: props.disableAnimation }]"
      :aria-hidden="!active"
      role="tooltip"
    >
      <div
        ref="arrowEl"
        :style="arrowElementStyle"
        :class="[
          $style.arrow,
          {
            [$style.arrowLeft]: basePlacement === 'left',
            [$style.arrowTop]: basePlacement === 'top',
            [$style.arrowRight]: basePlacement === 'right',
            [$style.arrowBottom]: basePlacement === 'bottom',
          },
        ]"
      />
      <slot>
        {{ props.content }}
      </slot>
    </div>
  </ObUnmountableTeleportWithTransition>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

$bg-color: #9fa8cd; // TODO: use design token

.root {
  pointer-events: none;
  background: $bg-color;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  white-space: pre-line;
  font-family: typography.$font-family-primary;
  padding: 10px;
  border-radius: shared.$border-radius-s;
  box-sizing: border-box;
  will-change: transform;
  max-width: 320px;
}

.arrow {
  position: absolute;
  width: 12px;
  height: 12px;

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
  }
}

.arrowLeft {
  left: 100%;

  &::before {
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent $bg-color;
    left: 0;
    top: 0;
  }
}

.arrowRight {
  right: 100%;

  &::before {
    border-width: 6px 6px 6px 0;
    border-color: transparent $bg-color transparent transparent;
    right: 0;
    top: 0;
  }
}

.arrowTop {
  top: 100%;

  &::before {
    border-width: 6px 6px 0 6px;
    border-color: $bg-color transparent transparent transparent;
    top: 0;
    left: 0;
  }
}

.arrowBottom {
  bottom: 100%;

  &::before {
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent $bg-color transparent;
    bottom: 0;
    left: 0;
  }
}

.enterActive,
.leaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.2s;
}

.animationDisabled {
  transition: none;
}

.enterActive {
  transition-timing-function: ease-out;
}

.leaveActive {
  transition-timing-function: ease-in;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: scale(0.9);
}
</style>
