<script lang="ts" setup>
import { type VNode } from 'vue';
import { injectStrict } from '../../utils';
import { CELL_CONTEXT } from './shared';

interface Props {
  align?: 'start' | 'end' | 'center';
  monospace?: boolean;
  clickable?: boolean;
  active?: boolean;
}

const props = defineProps<Props>();

defineSlots<{
  default?: (props: { value: string; rawValue: any }) => VNode;
}>();

const { align, monospace, value, rawValue } = injectStrict(CELL_CONTEXT);
</script>

<template>
  <div
    :role="props.clickable ? 'button' : undefined"
    :tabindex="props.clickable ? 0 : undefined"
    :class="[
      $style.root,
      {
        [$style.clickable]: props.clickable,
        [$style.alignEnd]: align === 'end',
        [$style.alignCenter]: align === 'center',
        [$style.monospace]: monospace,
        [$style.active]: props.active,
      },
    ]"
  >
    <slot v-bind="{ value, rawValue }" />
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';
@use './shared' as tableSharedStyles;

.root {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex: none;
  padding: 12px;
  width: 100%;
  align-items: center;
  font-family: typography.$font-family-primary;
  font-size: 13px;
  line-height: 16px;
  min-height: 40px;
  overflow: hidden;

  &:hover {
    background: rgba(#23395d, 0.05);
  }
}

.clickable {
  cursor: pointer;
  user-select: none;
  z-index: tableSharedStyles.$z-index-clickable-cel;

  &:focus-visible {
    box-shadow: inset 0 0 0 2px #3355ff;
  }

  &.active,
  &:active {
    background: rgba(#23395d, 0.05);
    box-shadow: inset 0 0 0 2px #7e57c2;
  }
}

.content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alignCenter {
  justify-content: center;
}

.alignEnd {
  justify-content: flex-end;
}

.monospace {
  font-family: typography.$font-family-monospace;
}
</style>
