import { ref, watch, onBeforeMount, provide, inject, readonly } from 'vue';
import type { Ref, InjectionKey } from 'vue';
import { uniq } from 'lodash-es';
import { unrefElement, useActiveElement, tryOnScopeDispose } from '@vueuse/core';
import type { MaybeElementRef } from '@vueuse/core';
import { getNativeFocused } from '../../utils';

// This composable provides a way to implement something like :focus-within CSS pseudo-class with elements that are not nested.
// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-within

// TODO: do we need parent injection?

// TODO: is there a way to optimize `isActive()` calls?
// When focus changes we have to call it for parent zone and all of it's children.
// computed with `useActiveElement` dependency doesn't work because it updates after parent

interface UseFocusScopeOptions {
  autoInjectParent?: boolean;
  parentScopeId?: symbol;
}

interface UseFocusScopeReturn {
  id: symbol;
  readonly active: Ref<boolean>;
}

interface Scope {
  addChild: (id: symbol) => void;
  removeChild: (id: symbol) => void;
  isActive: () => boolean;
  hasElement: (element: Element) => boolean;
}

const scopesMap = new Map<symbol, Scope>();

const PARENT_FOCUS_SCOPE_ID: InjectionKey<symbol> = Symbol(
  __DEV__ ? 'Injected parent focus scope id' : '',
);

export function useFocusScope(
  containerRef: MaybeElementRef,
  options: UseFocusScopeOptions = {},
): UseFocusScopeReturn {
  const id = Symbol(__DEV__ ? 'focus scope id' : '');
  const activeElement = useActiveElement();
  const children = ref<symbol[]>([]);
  const active = ref(false);

  provide(PARENT_FOCUS_SCOPE_ID, id);

  const { autoInjectParent = true } = options;

  const parentScopeId =
    autoInjectParent && !options.parentScopeId
      ? inject(PARENT_FOCUS_SCOPE_ID, undefined)
      : options.parentScopeId;

  function addChild(scopeId: symbol) {
    children.value = uniq([...children.value, scopeId]);
  }

  function removeChild(scopeId: symbol) {
    children.value = children.value.filter((item) => item !== scopeId);
  }

  function hasElement(element: Element) {
    const containerElement = unrefElement(containerRef);
    return containerElement
      ? element === containerElement || containerElement.contains(element)
      : false;
  }

  function isElementInFocusScope(element: Element) {
    if (hasElement(element)) {
      return true;
    }

    return children.value.some((scopeId: symbol) => {
      const scope = scopesMap.get(scopeId);
      return scope?.isActive() ?? false;
    });
  }

  function isActive() {
    // We have to use `getNativeFocused()` here because `activeElement` is not updated
    // by the time parent scope update.
    const focused = getNativeFocused();
    return focused ? isElementInFocusScope(focused) : false;
  }

  function getParentScope(): Scope | undefined {
    return parentScopeId ? scopesMap.get(parentScopeId) : undefined;
  }

  const scope: Scope = {
    addChild,
    removeChild,
    isActive,
    hasElement,
  };

  // TODO: check if it works with effect scope
  onBeforeMount(() => {
    scopesMap.set(id, scope);

    const currentParent = getParentScope();

    if (currentParent) {
      currentParent.addChild(id);
    }
  });

  tryOnScopeDispose(() => {
    scopesMap.delete(id);

    const currentParent = getParentScope();

    if (currentParent) {
      currentParent.removeChild(id);
    }
  });

  watch(
    [activeElement, containerRef],
    () => {
      active.value = isActive();
    },
    { immediate: true },
  );

  return {
    id,
    active: readonly(active),
  };
}
