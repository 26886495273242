<script lang="ts" setup>
import { computed, watch, toRef } from 'vue';
import { useOffsetPagination } from '@vueuse/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-vue';
import { ObSpace } from '../space';
import { UiButtonIcon } from '../ui-button-icon';

// References:
// https://zagjs.com/components/react/pagination
// https://hds.hel.fi/components/pagination
// https://clayui.com/docs/components/pagination.html
// https://seek-oss.github.io/braid-design-system/components/Pagination

// TODO: render pages https://mui.com/material-ui/react-pagination/#pagination-ranges

// TODO: a11y

// TODO: support router links (see references for possible solutions)
// https://mui.com/material-ui/react-pagination/#router-integration

interface Props {
  total?: number;
  modelValue?: number;
  pageSize?: number;
}

const props = withDefaults(defineProps<Props>(), {
  total: 0,
  modelValue: 1,
  pageSize: 10,
});

const emit = defineEmits<{
  'update:modelValue': [value: number];
  next: [];
  prev: [];
}>();

const pageSize = toRef(props, 'pageSize');

const { currentPage, isFirstPage, isLastPage, prev, next } = useOffsetPagination({
  total: computed(() => props.total),
  page: props.modelValue, // cannot use computed here and have to use watcher because of `useClamp` inside `useOffsetPagination` (see sources)
  pageSize: () => pageSize.value,
  onPageChange: ({ currentPage }) => {
    emit('update:modelValue', currentPage);
  },
});

watch(
  () => props.modelValue,
  (value) => {
    currentPage.value = value;
  },
);

function onClickPrev() {
  emit('prev');
  prev();
}

function onClickNext() {
  emit('next');
  next();
}
</script>

<template>
  <ObSpace align-y="center">
    <UiButtonIcon
      :disabled="isFirstPage"
      variant="tertiary"
      aria-label="Go to previous page"
      @click="onClickPrev"
    >
      <IconChevronLeft aria-hidden="true" />
    </UiButtonIcon>
    <UiButtonIcon
      :disabled="isLastPage"
      variant="tertiary"
      aria-label="Go to next page"
      @click="onClickNext"
    >
      <IconChevronRight aria-hidden="true" />
    </UiButtonIcon>
  </ObSpace>
</template>
