<script lang="ts" setup generic="T extends GenericObject">
import { provide } from 'vue';
import type { GenericObject } from '../../../shared/types';
import type { UiDataTableColumn } from '../interfaces';
import { CELL_CONTEXT } from '../shared';
import BaseCell from './base-cell.vue';

type Row = T;
type Column = UiDataTableColumn<T>;

interface Props {
  column?: Column;
  row?: Row;
  value?: string;
  rawValue?: any;
}

const props = defineProps<Props>();

provide(CELL_CONTEXT, {
  align: props.column?.align ?? 'start',
  monospace: props.column?.monospace ?? false,
  value: props.value ?? '',
  rawValue: props.rawValue,
});
</script>

<template>
  <BaseCell :column="props.column">
    <slot />
  </BaseCell>
</template>
