<script lang="ts" setup generic="T extends string | Component">
import {
  useSlots,
  useAttrs,
  useCssModule,
  h,
  mergeProps,
  computed,
  type VNodeChild,
  type Component,
} from 'vue';
import type { PolymorphicProps } from '../../shared/types';

const props = defineProps<
  PolymorphicProps<T> & {
    disabled?: boolean;
  }
>();

defineSlots<{
  default?: () => VNodeChild;
  meta?: () => VNodeChild;
  icon?: () => VNodeChild;
}>();

const slots = useSlots();
const attrs = useAttrs();
const style = useCssModule();

const rootProps = computed(() => ({
  class: [style.root, { [style.disabled]: props.disabled }],
}));

defineRender(() => {
  const content = slots.default?.();
  const meta = slots.meta?.();
  const icon = slots.icon?.();

  const internalStructure = [
    h('div', { class: style.body }, content),
    h('div', { class: style.footer }, [
      h('div', { class: style.meta }, meta),
      h('div', { class: style.icon }, icon),
    ]),
  ];

  if (props.as) {
    return h(props.as, mergeProps(attrs, rootProps.value, props.asProps ?? {}), internalStructure);
  }

  return h(
    'button',
    { ...rootProps.value, type: 'button', disabled: props.disabled },
    internalStructure,
  );
});
</script>

<style lang="scss" module>
@use '../../styles/shared';

.root {
  @include shared.reset-button;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  min-height: 90px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-style: normal;
  line-height: 16px;
  padding: 12px;
  color: #0e1a32;
  background-color: #f5f5f5;
  border-radius: 10px;

  &:hover:not(.disabled) {
    background: linear-gradient(0deg, rgba(#23395d, 0.05) 0%, rgba(#23395d, 0.05) 100%), #f5f5f5;
  }

  &:focus-visible {
    outline: 1px solid #085cd9;
    outline-offset: -1px;
  }
}

.disabled {
  cursor: not-allowed;
  color: #959ba6;
}

.body {
  flex: 1;
  font-weight: 500;
  width: 100%;
}

.footer {
  display: flex;
  gap: 10px;
  width: 100%;
}

.meta {
  color: #959ba6;
  flex: 1 0 auto;
  font-size: 12px;
}

.icon {
  width: 16px;
  height: 16px;
  color: #959ba6;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg,
  > img {
    width: 100%;
    height: 100%;
  }
}
</style>
