<script setup lang="ts">
import { provide, reactive, watch, toValue, type MaybeRefOrGetter } from 'vue';
import { merge } from 'lodash-es';
import { type UiConfig, UI_CONFIG_INJECTION_KEY, defaultUiConfig } from '../../shared/ui-config';
import { type DeepPartial } from '../../shared/types';

interface Props {
  config?: MaybeRefOrGetter<DeepPartial<UiConfig>>;
}

// TODO: add parent config injection support?

const props = defineProps<Props>();

function mergeConfigs() {
  return merge({}, defaultUiConfig, toValue(props.config));
}

const uiConfig = reactive<UiConfig>(mergeConfigs());

watch(
  () => props.config,
  () => {
    Object.assign(uiConfig, mergeConfigs());
  },
  { deep: true },
);

provide(UI_CONFIG_INJECTION_KEY, uiConfig);
</script>

<template>
  <slot />
</template>
