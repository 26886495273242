<script lang="ts">
const DEFAULT_COLUMN_WIDTH = 192
const MIN_COLUMN_WIDTH = 48
const MAX_COLUMN_WIDTH = 9999
const SCROLL_SPEED = 15
const EDGE_THRESHOLD = 100
const DRAG_START_THRESHOLD = 5
</script>
<script lang="ts" setup generic="T extends GenericObject = GenericObject">
import {
  ref,
  useTemplateRef,
  computed,
  watch,
  nextTick,
  provide,
  onBeforeUnmount,
  shallowReactive,
  toValue,
  type MaybeRefOrGetter,
  type VNodeChild,
} from 'vue';
import { unrefElement } from '@vueuse/core';
import { get, toString, sum, uniq } from 'lodash-es';
import { IconWindowMaximize, IconWindowMinimize, IconEyeOff } from '@tabler/icons-vue';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { Path, GenericObject } from '../../shared/types';
import type { UiConfig } from '../../shared/ui-config';
import {
  pxOrValue,
  sortByOrder,
  areValuesEqual,
  hasSlotContent,
  getValueByKey,
  resolveI18n,
} from '../../utils';
import { useZIndex, useUiConfig } from '../../composables';
import { ObSelect } from '../select';
import { ObSpace } from '../space';
import { ObSeparator } from '../separator';
import { ObLink } from '../link';
import { ObFlexGrid, ObFlexGridItem } from '../flex-grid';
import { ObPagination } from '../pagination';
import { ObCheckbox } from '../checkbox';
import { UiButtonIcon } from '../ui-button-icon';
import { ObSelectPanel } from '../select-panel';
import { ObScrollableContainer } from '../scrollable-container';
import type { UiDataTableColumn, UiDataTableSorting } from './interfaces';
import UiDataTableCell from './ui-data-table-cell.vue';
import { BodyCell, HeadCell, LoadingCell } from './internal';
import { TABLE_CONTEXT } from './shared';

type Row = T;
type Column = UiDataTableColumn<T>;

interface Props {
  columns: Column[];
  rows: Row[];
  rowKey: Path<Row>;
  fullscreenControl?: boolean;
  page?: number;
  pageSize?: number;
  totalRows?: number;
  externalPagination?: boolean;
  sorting?: UiDataTableSorting;
  externalSorting?: boolean;
  rowsSelection?: boolean;
  rowSelectionDisabled?: Path<Row> | ((row: Row) => boolean);
  selectedRows?: Row[];
  allRowsSelection?: boolean;
  allRowsSelected?: boolean;
  visibleColumns?: string[];
  columnsOrder?: string[];
  pinnedColumns?: string[];
  loading?: boolean;
  i18n?: MaybeRefOrGetter<Partial<UiConfig['i18n']['dataTable']>>;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  columnResize: [{ key: string; width: number }];
  pageSizeChange: [pageSize: number];
  paginate: [page: number];
  paginateNext: [];
  paginatePrev: [];
  columnsSettingsOpened: [];
  columnsSettingsClosed: [];
}>();

type Slots = {
  [key: `cell[${string}]`]: (props: {
    row: Row;
    column: Column;
    rawValue: any;
    value: string;
    rowIndex: number;
  }) => VNodeChild;
  [key: `header[${string}]`]: (props: { column: Column }) => VNodeChild;
  toolbarStart: (props: { fullscreen: boolean }) => VNodeChild;
  toolbarEnd: (props: { fullscreen: boolean }) => VNodeChild;
  columnsSettingsFooter: () => VNodeChild;
  noData: () => VNodeChild;
};

defineSlots<Slots>();

// Config

const uiConfig = useUiConfig();

const i18n = computed(() => {
  return { ...uiConfig.i18n.dataTable, ...toValue(props.i18n) };
});

// Template refs

const containerRef = useTemplateRef('container');
const tableRef = useTemplateRef('table');
const headRowRef = useTemplateRef('headRow');
const ghostRef = useTemplateRef('ghost');

const scrollableContainer = computed(() => {
  const element = unrefElement(tableRef);

  if (!element) {
    return null;
  }

  return element as HTMLElement;
});

// Pagination

const page = defineModel<number>('page', { default: 1 });
const pageSize = defineModel<number>('pageSize', { default: 25 });
const pageSizeOptions = defineModel<number[]>('pageSizeOptions', { default: [25, 50, 100] });
const totalRows = computed(() => props.totalRows ?? props.rows.length);
const labelRowsPerPage = computed(() => {
  return 'Rows';
});
const labelDisplayedRows = computed(() => {
  const currentPageStart = (page.value - 1) * pageSize.value + 1;
  const currentPageEnd = Math.min(page.value * pageSize.value, totalRows.value);
  return `${currentPageStart}-${currentPageEnd} of ${totalRows.value}`;
});

watch([page, pageSize], () => {
  if (scrollableContainer.value) {
    scrollableContainer.value.scrollTop = 0;
  }
});

// Sorting

const sorting = defineModel<UiDataTableSorting>('sorting', {
  default: { sortBy: null, sortOrder: 'asc' },
});

function setSorting(value: UiDataTableSorting) {
  sorting.value = value;
  page.value = 1;
}

// Columns

const columnsKeys = computed(() => props.columns.map(({ key }) => key));

const visibleColumnKeys = defineModel<string[] | undefined>('visibleColumns');

const visibleColumnKeysProxy = computed({
  get() {
    return visibleColumnKeys.value ?? columnsKeys.value;
  },
  set(value) {
    visibleColumnKeys.value = value;
  },
});

function hideColumn(key: string) {
  visibleColumnKeysProxy.value = visibleColumnKeysProxy.value.filter((item) => item !== key);
}

const columnsOrder = defineModel<string[]>('columnsOrder', { default: [] });

const pinnedColumnsKeys = defineModel<string[]>('pinnedColumns', { default: [] });

const orderedColumns = computed<Column[]>(() => {
  const result =
    Array.isArray(columnsOrder.value) && columnsOrder.value.length
      ? sortByOrder(props.columns, columnsOrder.value, 'key')
      : [...props.columns];

  return result.sort((a, b) => {
    // Frozen columns go first
    if (a.frozen || b.frozen) {
      if (a.frozen === b.frozen) {
        return 0;
      }

      return a.frozen ? -1 : 1;
    }

    const aPinned = pinnedColumnsKeys.value.includes(a.key);
    const bPinned = pinnedColumnsKeys.value.includes(b.key);

    if (aPinned === bPinned) {
      return 0;
    }

    return aPinned ? -1 : 1;
  });
});

const visibleColumns = computed<Column[]>(() => {
  return Array.isArray(visibleColumnKeysProxy.value)
    ? orderedColumns.value.filter(
        ({ key, frozen }) => frozen || visibleColumnKeysProxy.value?.includes(key),
      )
    : orderedColumns.value;
});

const regularColumns = computed<Column[]>(() =>
  visibleColumns.value.filter(
    ({ key, frozen }) => !frozen && !pinnedColumnsKeys.value.includes(key),
  ),
);

const regularColumnsKeys = computed(() => regularColumns.value.map(({ key }) => key));

const pinnedColumns = computed<Column[]>(() =>
  visibleColumns.value.filter(({ key, frozen }) => frozen || pinnedColumnsKeys.value.includes(key)),
);

const columnsForSettings = computed(() => orderedColumns.value.filter(({ frozen }) => !frozen));

// Columns width





const columnWidths = shallowReactive<Record<string, number>>({});
let columnWidthsUpdate: Record<string, number> = {};

const columnMinMaxWidths = computed(() => {
  return props.columns.reduce<Record<string, { minWidth: number; maxWidth: number }>>(
    (acc, { key, minWidth = MIN_COLUMN_WIDTH, maxWidth = MAX_COLUMN_WIDTH }) => {
      acc[key] = {
        minWidth,
        maxWidth,
      };
      return acc;
    },
    {},
  );
});

watch(
  () => props.columns,
  (value) => {
    Object.assign(
      columnWidths,
      value.reduce<Record<string, number>>((acc, { key, width }) => {
        acc[key] = width ?? DEFAULT_COLUMN_WIDTH;
        return acc;
      }, {}),
    );
  },
  { immediate: true, deep: true },
);

// Set styles manually to not use Vue reactivity system during resizing for better performance
function applyColumnStyles() {
  let gridTemplatePinned = pinnedColumns.value
    .map(({ key }) => pxOrValue(columnWidthsUpdate[key] || columnWidths[key]))
    .join(' ');

  if (props.rowsSelection) {
    gridTemplatePinned = `40px ${gridTemplatePinned}`;
  }

  const gridTemplateRegular = regularColumns.value
    .map(({ key }) => pxOrValue(columnWidthsUpdate[key] || columnWidths[key]))
    .join(' ');

  if (scrollableContainer.value) {
    scrollableContainer.value.style.setProperty('--grid-template-pinned', gridTemplatePinned);
    scrollableContainer.value.style.setProperty('--grid-template-regular', gridTemplateRegular);
  }
}

watch(
  [columnWidths, pinnedColumnsKeys, regularColumnsKeys, scrollableContainer],
  () => {
    applyColumnStyles();
  },
  {
    immediate: true,
  },
);

const resizing = ref(false);
const resizingColumnKey = ref<string | null>(null);

function fixColumnWith(key: string, width: number) {
  return Math.min(
    Math.max(width, columnMinMaxWidths.value[key].minWidth),
    columnMinMaxWidths.value[key].maxWidth,
  );
}

function getMaxContentWidth(element: HTMLElement): number {
  if (!element) {
    return 0;
  }

  const clone = element.cloneNode(true) as HTMLElement;
  document.body.appendChild(clone);

  clone.style.width = 'auto';
  clone.style.whiteSpace = 'nowrap';
  clone.style.visibility = 'hidden';
  clone.style.position = 'absolute';

  const { width } = clone.getBoundingClientRect();

  const computedStyle = window.getComputedStyle(clone);
  const borderLeft = parseFloat(computedStyle.borderLeftWidth);
  const borderRight = parseFloat(computedStyle.borderRightWidth);

  document.body.removeChild(clone);

  return Math.ceil(width + borderLeft + borderRight);
}

function autoAdjustColumnWidth(key: string) {
  if (!scrollableContainer.value) {
    return;
  }

  let newWidth = 0;

  // TODO: use template ref instead of data attribute?
  Array.from(scrollableContainer.value.querySelectorAll(`[data-column="${key}"]`)).forEach(
    (cell) => {
      const contentWidth = getMaxContentWidth(cell as HTMLElement);

      if (contentWidth > newWidth) {
        newWidth = contentWidth;
      }
    },
  );

  if (newWidth > 0) {
    columnWidths[key] = fixColumnWith(key, newWidth);
    emit('columnResize', { key, width: columnWidths[key] });
  }
}

function onResizeHandleMouseEnter(key: string) {
  if (resizing.value) {
    return;
  }

  resizingColumnKey.value = key;
}

function onResizeHandleMouseLeave() {
  if (resizing.value) {
    return;
  }

  resizingColumnKey.value = null;
}

function onResizeHandleMouseDown(event: MouseEvent, key: string) {
  if (event.button !== 0) {
    return;
  }

  event.preventDefault();
  event.stopPropagation();

  const startX = event.clientX;
  const startWidth = columnWidths[key];

  let animationFrame: ReturnType<typeof requestAnimationFrame> | null = null;

  function onMouseMove(event: MouseEvent) {
    resizing.value = true;

    if (animationFrame) {
      return;
    }

    animationFrame = requestAnimationFrame(() => {
      columnWidthsUpdate[key] = fixColumnWith(key, startWidth + (event.clientX - startX));

      applyColumnStyles();

      animationFrame = null;
    });
  }

  function onMouseUp() {
    resizing.value = false;
    resizingColumnKey.value = null;
    Object.assign(columnWidths, columnWidthsUpdate);
    if (columnWidthsUpdate[key]) {
      emit('columnResize', { key, width: columnWidths[key] });
    }
    columnWidthsUpdate = {};

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
    // document.removeEventListener('mouseleave', onMouseUp);
  }

  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
  // document.addEventListener('mouseleave', onMouseUp);
}

function onResizeHandleDoubleClick(event: MouseEvent, key: string) {
  event.preventDefault();
  autoAdjustColumnWidth(key);
}

function pinColumn(key: string) {
  pinnedColumnsKeys.value = uniq([...pinnedColumnsKeys.value, key]);
}

function unpinColumn(key: string) {
  pinnedColumnsKeys.value = pinnedColumnsKeys.value.filter((item) => item !== key);
}

// Columns order

const dragging = ref(false);
const draggedColumnKey = ref<string | null>(null);
const targetColumnKey = ref<string | null>(null);

// Sync with real columns order in case some columns are missing in columnsOrder
const order = computed(() => orderedColumns.value.map(({ key }) => key));
const draggedColumnIndex = computed(() => {
  if (!draggedColumnKey.value) {
    return -1;
  }

  return order.value.findIndex((item) => item === draggedColumnKey.value);
});
const targetColumnIndex = computed(() => {
  if (!targetColumnKey.value) {
    return -1;
  }

  return order.value.findIndex((item) => item === targetColumnKey.value);
});

const ghostText = computed(() => {
  if (!draggedColumnKey.value) {
    return '';
  }

  const column = visibleColumns.value.find(({ key }) => key === draggedColumnKey.value);

  return column?.heading ?? '';
});

let scrollInterval: ReturnType<typeof setInterval> | null;

let pinnedOffsets: number[] = [];
let regularOffsets: number[] = [];
let pinnedTotalWidth: number = 0;
let columnsOffset: number = 0;





function startScrolling(speed: number) {
  if (scrollInterval || !scrollableContainer.value) {
    return;
  }

  const maxScroll = scrollableContainer.value.scrollWidth - scrollableContainer.value.offsetWidth;

  scrollInterval = setInterval(() => {
    if (scrollableContainer.value) {
      scrollableContainer.value.scrollLeft = Math.max(
        0,
        Math.min(scrollableContainer.value.scrollLeft + speed, maxScroll),
      );
    }
  }, 30);
}

function stopScrolling() {
  if (scrollInterval) {
    clearInterval(scrollInterval);
    scrollInterval = null;
  }
}

function findIndexInOffsets(offsets: number[], value: number) {
  return offsets.findIndex((offset, index) => {
    return value >= offset && value < (offsets[index + 1] ?? Infinity);
  });
}

function updateGhostPosition(left: number) {
  ghostRef.value?.style.setProperty('transform', `translateX(${pxOrValue(left)})`);
}

function onDragMove(event: MouseEvent) {
  if (!dragging.value) {
    return;
  }

  event.preventDefault();

  if (!scrollableContainer.value) {
    return;
  }

  const { left, right } = scrollableContainer.value.getBoundingClientRect();
  const { scrollLeft } = scrollableContainer.value;

  let animationFrame: ReturnType<typeof requestAnimationFrame> | null = null;

  if (!animationFrame) {
    animationFrame = requestAnimationFrame(() => {
      updateGhostPosition(event.clientX - left + scrollLeft);
      animationFrame = null;
    });
  }

  let eventX = event.clientX - left - columnsOffset;

  if (eventX < 0) {
    return;
  }

  if (eventX <= pinnedTotalWidth) {
    const index = findIndexInOffsets(pinnedOffsets, eventX);

    const column = pinnedColumns.value[index];

    targetColumnKey.value = column && !column.frozen ? column.key : null;
    return;
  }

  eventX = eventX + scrollableContainer.value.scrollLeft;

  const index = findIndexInOffsets(regularOffsets, eventX);

  const column = regularColumns.value[index];

  targetColumnKey.value = column && !column.frozen ? column.key : null;

  if (event.clientX < left + EDGE_THRESHOLD) {
    startScrolling(-SCROLL_SPEED);
  } else if (event.clientX > right - EDGE_THRESHOLD) {
    startScrolling(SCROLL_SPEED);
  } else {
    stopScrolling();
  }
}

function onPressEscapeWhileDragging(event: KeyboardEvent) {
  if (event.key === 'Escape') {
    event.preventDefault();

    stopDragging();
  }
}

function onDragEnd(event: MouseEvent) {
  event.preventDefault();

  if (
    targetColumnKey.value &&
    draggedColumnKey.value &&
    draggedColumnKey.value !== targetColumnKey.value
  ) {
    const newOrder = [...order.value];

    newOrder.splice(draggedColumnIndex.value, 1);
    newOrder.splice(targetColumnIndex.value, 0, draggedColumnKey.value);

    columnsOrder.value = newOrder;

    const targetPinned = pinnedColumnsKeys.value.includes(targetColumnKey.value);
    const draggedPinned = pinnedColumnsKeys.value.includes(draggedColumnKey.value);

    if (draggedPinned && !targetPinned) {
      pinnedColumnsKeys.value = pinnedColumnsKeys.value.filter(
        (key) => key !== draggedColumnKey.value,
      );
    } else if (!draggedPinned && targetPinned) {
      pinnedColumnsKeys.value.push(draggedColumnKey.value);
    }
  }

  stopDragging();
}

function calculateOffsetsFromWidth(widths: number[]) {
  let sum = 0;
  return widths.map((width) => {
    const prevSum = sum;
    sum += width;
    return prevSum;
  });
}

function startDragging(event: MouseEvent, key: string) {
  const { x } = event;

  function onMouseMove(event: MouseEvent) {
    if (Math.abs(x - event.x) < DRAG_START_THRESHOLD) {
      return;
    }

    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('mousemove', onMouseMove);

    event.preventDefault();

    dragging.value = true;
    draggedColumnKey.value = key;

    const pinnedWidths = pinnedColumns.value?.map(({ key }) => columnWidths[key]);
    pinnedOffsets = calculateOffsetsFromWidth(pinnedWidths);
    pinnedTotalWidth = sum(pinnedWidths);

    const regularWidths = regularColumns.value?.map(({ key }) => columnWidths[key]);
    regularOffsets = calculateOffsetsFromWidth(regularWidths).map(
      (offset) => offset + pinnedTotalWidth,
    );

    const firstColumn = headRowRef.value?.querySelector<HTMLElement>('[data-column]');
    columnsOffset = firstColumn ? firstColumn.offsetLeft : 0;

    if (scrollableContainer.value) {
      const { left } = scrollableContainer.value.getBoundingClientRect();
      const { scrollLeft } = scrollableContainer.value;
      updateGhostPosition(event.clientX - left + scrollLeft);
    }

    document.addEventListener('mousemove', onDragMove);
    document.addEventListener('mouseup', onDragEnd);
    document.addEventListener('keydown', onPressEscapeWhileDragging);
  }

  function onMouseUp() {
    document.removeEventListener('mousemove', onMouseMove);
  }

  // Wait until user starts moving. Otherwise consider event as a click and ignore it.
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp, { once: true });
}

function stopDragging() {
  dragging.value = false;
  draggedColumnKey.value = null;
  targetColumnKey.value = null;

  stopScrolling();

  document.removeEventListener('mousemove', onDragMove);
  document.removeEventListener('mouseup', onDragEnd);
  document.removeEventListener('keydown', onPressEscapeWhileDragging);
}

onBeforeUnmount(() => {
  stopDragging();
});

// Grid

interface GridCell {
  key: string;
  value: string;
  rawValue: any;
  row: Row;
  column: Column;
}

interface GridRowGroup {
  cells: GridCell[];
  pinned?: boolean;
  rowsSelection?: boolean;
}

interface GridRow {
  key: string;
  groups: GridRowGroup[];
  row: Row;
  selected: boolean;
  selectionDisabled: boolean;
}

function getCellValue(row: Row, column: Column): unknown {
  if (typeof column.valueGetter === 'function') {
    return column.valueGetter(row);
  }

  if (typeof column.value === 'undefined' || column.value === null) {
    return null;
  }

  return get(row, column.value);
}

function createGridCell(row: Row, column: Column): GridCell {
  const { key } = column;

  const rawValue = getCellValue(row, column);

  const value = toString(
    typeof column.valueFormatter === 'function'
      ? column.valueFormatter(rawValue as any, row)
      : rawValue,
  );

  return { key, value, rawValue, row, column };
}

const selectedRows = defineModel<Row[]>('selectedRows', { default: [] });
const allRowsSelected = defineModel<boolean>('allRowsSelected', { default: false });

function clearRowsSelection() {
  selectedRows.value = [];
  allRowsSelected.value = false;
}

const grid = computed(() => {
  return props.rows.reduce<GridRow[]>((acc, row, index) => {
    const key = get(row, props.rowKey, toString(index));

    const cells: GridCell[] = regularColumns.value.map((column) => createGridCell(row, column));
    const pinnedCells: GridCell[] = pinnedColumns.value.map((column) =>
      createGridCell(row, column),
    );

    const groups: GridRowGroup[] = [
      {
        cells,
      },
    ];

    if (pinnedCells.length || props.rowsSelection) {
      groups.unshift({
        pinned: true,
        rowsSelection: props.rowsSelection,
        cells: pinnedCells,
      });
    }

    const selected =
      allRowsSelected.value ||
      selectedRows.value.some((item) => areValuesEqual(item, row, props.rowKey));

    let selectionDisabled = false;

    if (props.rowSelectionDisabled) {
      selectionDisabled = getValueByKey(row, props.rowSelectionDisabled) ?? false;
    }

    acc.push({ key, groups, row, selected, selectionDisabled });
    return acc;
  }, []);
});

const gridValues = computed(() => {
  return Object.fromEntries(
    grid.value.map((row) => [
      row.key,
      Object.fromEntries(
        row.groups.flatMap((group) => group.cells).map((cell) => [cell.key, cell.rawValue]),
      ),
    ]),
  );
});

const sortedGrid = computed(() => {
  if (props.externalSorting) {
    return grid.value;
  }

  const { sortBy, sortOrder = 'asc' } = sorting.value;

  if (sortBy === null) {
    return grid.value;
  }

  const column = props.columns.find(({ key }) => key === sortBy);

  if (!column) {
    return grid.value;
  }

  return [...grid.value].sort((a, b) => {
    if (typeof column.sortFn === 'function') {
      return column.sortFn(a.row, b.row, sortOrder);
    }

    const aValue = gridValues.value[a.key]?.[sortBy];
    const bValue = gridValues.value[b.key]?.[sortBy];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortOrder === 'desc' ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortOrder === 'desc' ? bValue - aValue : aValue - bValue;
    }

    if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
      if (aValue === bValue) {
        return 0;
      }

      if (aValue) {
        return sortOrder === 'desc' ? -1 : 1;
      }

      if (bValue) {
        return sortOrder === 'desc' ? 1 : -1;
      }
    }

    // TODO: try to compare string vs number / number vs string;
    return 0;
  });
});

const paginatedGrid = computed(() => {
  if (props.externalPagination) {
    return sortedGrid.value;
  }

  return sortedGrid.value.slice((page.value - 1) * pageSize.value, page.value * pageSize.value);
});

const allVisibleRowsSelected = computed<boolean>({
  set(value: boolean) {
    if (!value) {
      clearRowsSelection();
      return;
    }

    selectedRows.value = [
      ...paginatedGrid.value
        .filter(({ selectionDisabled }) => !selectionDisabled)
        .map(({ row }) => row),
    ];
  },
  get() {
    if (!selectedRows.value.length) {
      return false;
    }

    if (selectedRows.value.length !== paginatedGrid.value.length) {
      return false;
    }

    return !paginatedGrid.value.some(({ selected }) => !selected);
  },
});

const columnGroups = computed(() => {
  const result: Array<{
    columns: Column[];
    pinned?: boolean;
    rowsSelection?: boolean;
  }> = [{ columns: regularColumns.value }];

  if (pinnedColumns.value.length > 0 || props.rowsSelection) {
    result.unshift({
      columns: pinnedColumns.value,
      pinned: true,
      rowsSelection: props.rowsSelection,
    });
  }

  return result;
});

// Full screen

const containerHeight = ref<number | null>(null);

const fullscreen = ref(false);

const { zIndex } = useZIndex({
  active: fullscreen,
});

function toggleFullscreen() {
  const scrollTop = scrollableContainer.value?.scrollTop ?? 0;
  const scrollLeft = scrollableContainer.value?.scrollLeft ?? 0;

  if (fullscreen.value) {
    containerHeight.value = null;
  } else {
    containerHeight.value = containerRef.value?.offsetHeight || null;
  }

  fullscreen.value = !fullscreen.value;

  nextTick(() => {
    if (scrollableContainer.value) {
      scrollableContainer.value.scrollTop = scrollTop;
      scrollableContainer.value.scrollLeft = scrollLeft;

      if (fullscreen.value) {
        disableBodyScroll(scrollableContainer.value, {
          reserveScrollBarGap: true,
        });
      } else {
        enableBodyScroll(scrollableContainer.value);
      }
    }
  });
}

provide(TABLE_CONTEXT, {
  resizingColumnKey, // TODO: maybe for consistency listen for @start-resizing event?
  onResizeHandleMouseEnter,
  onResizeHandleMouseLeave,
  onResizeHandleMouseDown,
  onResizeHandleDoubleClick,
  startDragging,
  pinnedColumnsKeys,
  hideColumn,
  sorting,
  setSorting,
  draggedColumnIndex,
  targetColumnIndex,
  draggedColumnKey,
  targetColumnKey,
  regularColumns,
  pinnedColumns,
  pinColumn,
  unpinColumn,
});

const loadingRowsCount = computed(() => {
  if (paginatedGrid.value.length > 0) {
    return paginatedGrid.value.length;
  }

  return pageSize.value;
});

const hasPagination = computed(() => {
  return totalRows.value > Math.min(...pageSizeOptions.value);
});

const hasSelectAll = computed(
  () => props.allRowsSelection && allVisibleRowsSelected.value && totalRows.value > pageSize.value,
);

function onPageSizeChange(value: number) {
  emit('pageSizeChange', value);
}

function onPaginate(value: number) {
  emit('paginate', value);
}

function onPaginateNext() {
  emit('paginateNext');
}

function onPaginatePrev() {
  emit('paginatePrev');
}

function onColumnsSettingsPanelToggle(open: boolean) {
  if (open) {
    emit('columnsSettingsOpened');
    return;
  }

  emit('columnsSettingsClosed');
}
</script>

<template>
  <div :class="$style.frame">
    <div
      v-if="fullscreen"
      :style="{ height: containerHeight ? `${containerHeight}px` : undefined }"
    />
    <Teleport to="body" :disabled="!fullscreen">
      <div
        ref="container"
        :class="[$style.container, { [$style.containerFullscreen]: fullscreen }]"
        :style="{ zIndex }"
      >
        <div :class="$style.toolbar">
          <div
            v-if="hasSelectAll || hasSlotContent($slots.toolbarStart)"
            :class="$style.toolbarStart"
          >
            <div v-if="hasSelectAll" :class="$style.selectAll">
              <ObSpace align-y="center">
                <div>
                  {{
                    resolveI18n(
                      i18n,
                      'itemsSelected',
                      allRowsSelected ? totalRows : selectedRows.length,
                    )
                  }}
                </div>
                <ObSeparator vertical spacing="4" />
                <div :class="$style.selectAllButtons">
                  <ObLink v-slot="{ rootProps }" as-child>
                    <button
                      v-if="allRowsSelected"
                      type="button"
                      v-bind="rootProps"
                      @click="clearRowsSelection()"
                    >
                      {{ resolveI18n(i18n, 'clearSelection') }}
                    </button>
                    <button
                      v-else-if="true"
                      type="button"
                      v-bind="rootProps"
                      @click="allRowsSelected = true"
                    >
                      {{ resolveI18n(i18n, 'selectAllItems', totalRows) }}
                    </button>
                  </ObLink>
                </div>
              </ObSpace>
            </div>
            <slot v-else name="toolbarStart" v-bind="{ fullscreen }" />
          </div>
          <div :class="$style.toolbarEnd">
            <ObSpace spacing="3">
              <slot name="toolbarEnd" v-bind="{ fullscreen }" />
              <ObSelectPanel
                v-model="visibleColumnKeysProxy"
                :title="resolveI18n(i18n, 'columnsSettingsTitle')"
                with-search
                with-select-all
                :options="columnsForSettings"
                option-value="key"
                option-label="heading"
                :search-placeholder="resolveI18n(i18n, 'columnsSettingsSearchHint')"
                selection-mode="multiple"
                @update:open="onColumnsSettingsPanelToggle"
              >
                <template #host="{ hostProps, open }">
                  <UiButtonIcon v-bind="hostProps" :active="open" variant="tertiary">
                    <IconEyeOff aria-hidden="true" />
                    {{ resolveI18n(i18n, 'openColumnsSettings') }}
                  </UiButtonIcon>
                </template>
                <template #footer>
                  <slot name="columnsSettingsFooter" />
                </template>
              </ObSelectPanel>
              <UiButtonIcon v-if="fullscreenControl" variant="tertiary" @click="toggleFullscreen()">
                <IconWindowMinimize v-if="fullscreen" />
                <IconWindowMaximize v-else />
              </UiButtonIcon>
            </ObSpace>
          </div>
        </div>
        <div v-if="!props.loading && !paginatedGrid.length" :class="$style.noData">
          <div>
            <slot name="noData">No data</slot>
          </div>
        </div>
        <ObScrollableContainer
          v-else
          ref="table"
          :class="[
            $style.table,
            { [$style.tableReordering]: dragging, [$style.tableResizing]: resizing },
          ]"
          role="grid"
        >
          <div :class="$style.tableHead" role="rowgroup">
            <div ref="headRow" :class="$style.headRow" role="row">
              <div
                v-for="(group, groupIndex) in columnGroups"
                :key="groupIndex"
                :class="[$style.columns, { [$style.columnsPinned]: group.pinned }]"
                role="presentation"
              >
                <HeadCell v-if="group.rowsSelection" role="columnheader">
                  <ObCheckbox
                    v-model="allVisibleRowsSelected"
                    :indeterminate="selectedRows.length > 0 && !allVisibleRowsSelected"
                    :disabled="props.loading"
                  />
                </HeadCell>
                <HeadCell
                  v-for="(column, columnIndex) in group.columns"
                  :key="column.key ?? columnIndex"
                  :data-column="column.key"
                  :column="column"
                  role="columnheader"
                >
                  <slot :name="`header[${column.key}]`" v-bind="{ column }">
                    {{ column.heading }}
                  </slot>
                </HeadCell>
              </div>
              <div v-if="dragging" ref="ghost" :class="$style.ghost">
                <div :class="$style.ghostInner">
                  {{ ghostText }}
                </div>
              </div>
            </div>
          </div>
          <div role="rowgroup" :class="$style.body">
            <template v-if="props.loading">
              <div :class="$style.loadingOverlay" />
              <div
                v-for="item in loadingRowsCount"
                :key="item"
                :class="[$style.row, $style.rowLoading]"
                role="row"
              >
                <div
                  v-for="(group, groupIndex) in columnGroups"
                  :key="groupIndex"
                  :class="[$style.columns, { [$style.columnsPinned]: group.pinned }]"
                  role="presentation"
                >
                  <LoadingCell v-if="group.rowsSelection" role="gridcell">
                    <div style="width: 16px" />
                  </LoadingCell>
                  <LoadingCell
                    v-for="(column, columnIndex) in group.columns"
                    :key="column.key ?? columnIndex"
                    :data-column="column.key"
                    :column="column"
                    role="gridcell"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(gridRow, gridRowIndex) in paginatedGrid"
                :key="gridRow.key"
                :class="[$style.row, { [$style.rowSelected]: gridRow.selected }]"
                role="row"
              >
                <div
                  v-for="(group, groupIndex) in gridRow.groups"
                  :key="groupIndex"
                  :class="[$style.columns, { [$style.columnsPinned]: group.pinned }]"
                  role="presentation"
                >
                  <BodyCell v-if="group.rowsSelection" role="gridcell">
                    <UiDataTableCell>
                      <ObCheckbox
                        v-model="selectedRows"
                        :track-by="props.rowKey"
                        :value="gridRow.row"
                        multiselect
                        :disabled="gridRow.selectionDisabled"
                      />
                    </UiDataTableCell>
                  </BodyCell>
                  <BodyCell
                    v-for="cell in group.cells"
                    :key="cell.key"
                    :column="cell.column"
                    :row="cell.row"
                    :value="cell.value"
                    :raw-value="cell.rawValue"
                    :data-column="cell.column.key"
                    role="gridcell"
                  >
                    <slot :name="`cell[${cell.key}]`" v-bind="{ ...cell, rowIndex: gridRowIndex }">
                      <UiDataTableCell>
                        {{ cell.value }}
                      </UiDataTableCell>
                    </slot>
                  </BodyCell>
                </div>
              </div>
            </template>
          </div>
        </ObScrollableContainer>
        <div v-if="hasPagination" :class="$style.footer">
          <ObFlexGrid justify-content="between" align-items="center">
            <ObFlexGridItem>
              <div :class="$style.pagination">
                <ObSpace spacing="4" align-y="center" size="auto">
                  <div>{{ labelRowsPerPage }}:</div>
                  <div>
                    <ObSelect
                      v-model="pageSize"
                      :options="pageSizeOptions"
                      size="s"
                      @update:model-value="onPageSizeChange($event as number)"
                    />
                  </div>
                </ObSpace>
              </div>
            </ObFlexGridItem>
            <ObFlexGridItem size="auto">
              <ObSpace spacing="4" align-y="center">
                <div>{{ labelDisplayedRows }}</div>
                <ObPagination
                  v-model="page"
                  :page-size="pageSize"
                  :total="totalRows"
                  @next="onPaginateNext"
                  @prev="onPaginatePrev"
                  @update:model-value="onPaginate"
                />
              </ObSpace>
            </ObFlexGridItem>
          </ObFlexGrid>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';
@use './shared' as tableSharedStyles;

.frame {
  flex: 1 1 0%;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  overflow: hidden;
  overflow-anchor: none;
  display: flex;
  min-width: 0;
  min-height: 0;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #f3f5fd;
  font-family: typography.$font-family-primary;
  font-size: 13px;
  line-height: 16px;
  color: #35416d;
  flex: 1 1 0%;
  overflow: hidden;
  text-align: left;
  background: #fff;
}

.containerFullscreen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  border-radius: 0;
}

.columns {
  position: relative;
  display: grid;
  grid-template-columns: var(--grid-template-regular);
}

.columnsPinned {
  position: sticky;
  left: 0;
  z-index: tableSharedStyles.$z-pinned-columns;
  border-right: 1px solid #d5d6d9;
  display: grid;
  grid-template-columns: var(--grid-template-pinned);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.toolbarStart,
.toolbarEnd {
  box-sizing: border-box;
  padding: 8px;
}

.toolbarEnd {
  display: flex;
  justify-content: flex-end;
  flex-basis: 0;
  flex-grow: 1;
}

.table {
  flex: 1 1 0%;
  font-size: 14px;
  line-height: 20px;
  font-family: typography.$font-family-primary;
  background: #fff;
  contain: layout style paint;
}

// Prevent hover effects when resizing/reordering
.tableResizing {
  cursor: col-resize;

  > * {
    pointer-events: none;
  }
}

.tableReordering {
  cursor: grabbing;

  > * {
    pointer-events: none;
  }
}

.tableHead {
  position: sticky;
  top: 0;
  z-index: tableSharedStyles.$z-index-head;
}

.headRow,
.row {
  position: relative;
  display: flex;
  width: max-content;
  min-width: 100%;
  box-sizing: border-box;
}

.headRow {
  &,
  & > .columns {
    background: #f5f5f5;
  }
}

.row {
  &,
  & > .columns {
    background: #fff;
  }

  &:not(.rowLoading) {
    &:hover,
    &:hover > .columns {
      background: linear-gradient(0deg, rgba(#23395d, 0.05) 0%, rgba(#23395d, 0.05) 100%), #fff;
    }
  }
}

.headRow,
.row {
  &:after,
  & > .columns:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: tableSharedStyles.$border-color;
    z-index: tableSharedStyles.$z-index-border;
    pointer-events: none;
  }
}

.rowSelected {
  &,
  & > .columns {
    background-color: #f8f7fe;
  }
}

.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 8px;
  background-color: #f5f5f5;
  border-top: 1px solid tableSharedStyles.$border-color;
  margin-top: -1px;
}

.ghost {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  z-index: tableSharedStyles.$z-index-ghost;
}

.ghostInner {
  position: relative;
  transform: translateX(-50%);
  box-sizing: border-box;
  min-width: 96px;
  width: auto;
  height: 100%;
  background: rgba(#fff, 0.75);
  padding: 10px 12px;
  font-weight: 500;
  align-items: center;
  font-family: typography.$font-family-primary;
  font-size: 14px;
  line-height: 20px;
  box-shadow: inset 0 0 0 1px #085cd9;
}

.pagination {
  padding-left: 8px;
}

.body {
  position: relative;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: tableSharedStyles.$z-index-loading-overlay;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  animation: wave 1.5s linear infinite;
  will-change: transform;

  @keyframes wave {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.noData {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.selectAll {
  padding: 6px 8px;
}

.selectAllButtons {
  font-weight: 500;
}
</style>
