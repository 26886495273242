<script lang="ts" setup>
import { computed, type VNodeChild } from 'vue';
import { hasSlotContent } from '../../utils';

interface Props {
  level?: '1' | '2' | '3' | '4';
  tag?: string;
}

const { level = '1', tag } = defineProps<Props>();

defineSlots<{
  icon?: () => VNodeChild;
  default?: () => VNodeChild;
  counter?: () => VNodeChild;
  after?: () => VNodeChild;
}>();

const computedTag = computed(() => {
  if (tag) {
    return tag;
  }

  switch (level) {
    case '4':
      return 'h4';
    case '3':
      return 'h3';
    case '2':
      return 'h2';
    case '1':
    default:
      return 'h1';
  }
});
</script>

<template>
  <component
    :is="computedTag"
    :class="[
      $style.root,
      {
        [$style.h1]: level === '1',
        [$style.h2]: level === '2',
        [$style.h3]: level === '3',
        [$style.h4]: level === '4',
      },
    ]"
  >
    <span v-if="hasSlotContent($slots.icon)" :class="$style.icon">
      <slot name="icon" />
    </span>
    <span :class="$style.main">
      <slot />
      <span v-if="hasSlotContent($slots.counter)" :class="$style.counter">
        <slot name="counter" />
      </span>
    </span>
    <span v-if="hasSlotContent($slots.after)" :class="$style.after">
      <slot name="after" />
    </span>
  </component>
</template>

<style lang="scss" module>
.root {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: #0e1a32;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.root,
.h1 {
  font-size: 38px;
  line-height: 40px;
  letter-spacing: -0.04em;
}

.h2 {
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.04em;
}

.h3 {
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.03em;
}

.h4 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.main {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.icon {
  flex: none;
  overflow: hidden;
  color: #959ba6;
  display: inline-flex;

  > img,
  > svg {
    width: 100%;
    height: 100%;
  }
}

.icon,
.h1 .icon {
  margin-top: 4px;
  width: 32px;
  height: 32px;
}

.h2 .icon {
  margin-top: 2px;
  width: 28px;
  height: 28px;
}

.h3 .icon {
  margin-top: 0;
  width: 24px;
  height: 24px;
}

.h4 .icon {
  margin-top: 1px;
  width: 18px;
  height: 18px;
}

.counter {
  color: #959ba6;
}

.after {
  display: flex;
  align-items: center;
}

.after,
.h1 .after {
  min-height: 40px;
}

.h2 .after {
  min-height: 32px;
}

.h3 .after {
  min-height: 24px;
}

.h4 .after {
  min-height: 20px;
}
</style>
