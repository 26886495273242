<script lang="ts" setup generic="O = any, V = any">
import {
  computed,
  watch,
  nextTick,
  useId,
  shallowRef,
  type VNode,
  type ComponentPublicInstance,
} from 'vue';
import { unrefElement } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { IconChevronDown } from '@tabler/icons-vue';
import { useFocusZone, useOptionsList, type OptionsListItem } from '../../composables';
import type { SizeS, SizeM, SizeL, OptionsListProps } from '../../shared/types';
import { getClosestFocusable, hasSlotContent } from '../../utils';
import { ObActionListContextProvider, ObActionList, ObActionListItem } from '../action-list';
import { ObScrollableContainer } from '../scrollable-container';
import { ObHostedDropdown } from '../hosted-dropdown';

type Props = OptionsListProps<O, V> & {
  invalid?: boolean;
  disabled?: boolean;
  modelValue?: V | null;
  placeholder?: string;
  size?: SizeS | SizeM | SizeL;
  open?: boolean;
};

defineOptions({
  inheritAttrs: false,
});

const {
  disabled = false,
  invalid = false,
  size = 'm',
  options = [],
  optionDisabled,
  optionLabel,
  optionValue,
  loadMissingOptions,
  trackValueBy,
} = defineProps<Props>();

defineEmits<{
  'update:modelValue': [value: V | null];
}>();

defineSlots<{
  default?: (props: {
    optionsList: OptionsListItem<O, V>[];
    selectOption: (option: OptionsListItem<O, V>) => void;
    selectedOption: OptionsListItem<O, V> | null;
  }) => VNode;
  prefix?: () => VNode;
  noOptions?: () => VNode;
}>();

const modelValue = defineModel<V | null>({ default: null });

const { optionsList, selectedOption } = useOptionsList<O, V>({
  options: computed(() => options), // reactivity
  optionDisabled,
  optionLabel,
  optionValue,
  loadMissingOptions,
  trackValueBy,
  selectionMode: 'single',
  selected: modelValue,
});

// TODO: make this methods part of useOptionsList()

function selectOption(option: OptionsListItem<O, V>) {
  modelValue.value = option.value;
}

const hostRef = shallowRef<HTMLElement | null>(null);
const containerRef = shallowRef<HTMLElement | null>(null);

const open = defineModel<boolean>('open', { default: false });

const { focusFirst, focusLast, focus } = useFocusZone({
  container: containerRef,
});

const { activate: activateTrap, deactivate: deactivateTrap } = useFocusTrap(containerRef, {
  escapeDeactivates: false,
  allowOutsideClick: true,
  clickOutsideDeactivates: true,
  returnFocusOnDeactivate: true,
  fallbackFocus: () => {
    return unrefElement(containerRef) ?? document.body;
  },
  setReturnFocus() {
    return hostRef.value ?? false;
  },
});

watch(
  open,
  (value) => {
    if (value) {
      nextTick(() => {
        activateTrap();
      });
      return;
    }

    deactivateTrap();
  },
  { immediate: true },
);

function onEsc(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();
  open.value = false;
  nextTick(() => {
    // TODO: maybe manage with useFocusTrap config?
    hostRef.value?.focus();
  });
}

function onTab(event: KeyboardEvent) {
  event.preventDefault();
  event.stopPropagation();

  open.value = false;

  nextTick(() => {
    if (!hostRef.value) {
      return;
    }

    const target =
      getClosestFocusable({
        initial: hostRef.value,
        root: document.documentElement,
        previous: event.shiftKey,
      }) ?? hostRef.value;

    target?.focus();
  });
}

function onHostClick(event: MouseEvent) {
  event.preventDefault();
  open.value = !open.value;

  if (open.value) {
    setTimeout(() => {
      const selected =
        unrefElement(containerRef)?.querySelector<HTMLElement>('[aria-selected="true"]');

      if (selected) {
        focus(selected);
      }
    });
  }
}

function onHostKeydown(event: KeyboardEvent) {
  if (event.key === 'ArrowDown') {
    event.preventDefault();
    open.value = true;
    setTimeout(() => {
      focusFirst();
    });
    return;
  }

  if (event.key === 'ArrowUp') {
    event.preventDefault();
    open.value = true;
    setTimeout(() => {
      focusLast();
    });
    return;
  }
}

function onItemSelect() {
  open.value = false;
}

const listId = useId();
</script>

<template>
  <ObHostedDropdown
    v-model:active="open"
    tabindex="-1"
    role="dialog"
    shift
    flip
    :padding="5"
    :size="{
      apply({ elements, availableHeight }) {
        const { width } = elements.reference.getBoundingClientRect();
        Object.assign(elements.floating.style, {
          width: `${width}px`,
          maxHeight: `${Math.max(0, availableHeight)}px`,
        });
      },
    }"
    @keydown.esc="onEsc"
    @keydown.tab="onTab"
    @click-outside="open = false"
  >
    <template #host="{ hostProps }">
      <button
        v-bind="hostProps"
        :ref="
          (el: Element | ComponentPublicInstance | null) => {
            hostProps.ref(el);
            hostRef = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
          }
        "
        type="button"
        :class="[
          $style.button,
          {
            [$style.disabled]: disabled,
            [$style.invalid]: invalid,
            [$style.focused]: open,
            [$style.sizeS]: size === 's',
            [$style.sizeM]: size === 'm',
            [$style.sizeL]: size === 'l',
          },
        ]"
        :disabled="disabled"
        aria-haspopup="listbox"
        :aria-expanded="open"
        :aria-controls="listId"
        :aria-owns="listId"
        aria-autocomplete="list"
        @click="onHostClick"
        @keydown="onHostKeydown"
      >
        <span v-if="hasSlotContent($slots.prefix)" :class="$style.prefix">
          <slot name="prefix" />
        </span>
        <span :class="$style.value">
          <template v-if="selectedOption">{{ selectedOption.label }}</template>
          <span v-else-if="placeholder" :class="$style.placeholder">
            {{ placeholder }}
          </span>
        </span>
        <span :class="[$style.arrow, { [$style.arrowRotated]: open }]">
          <IconChevronDown aria-hidden="true" />
        </span>
      </button>
    </template>
    <ObScrollableContainer ref="containerRef" light>
      <div v-if="!optionsList.length" :class="$style.noData">
        <slot name="noOptions"> No options </slot>
      </div>
      <ObActionListContextProvider
        v-else
        list-role="listbox"
        item-role="option"
        :on-after-select="onItemSelect"
      >
        <ObActionList :id="listId" selection-mode="single" compact>
          <slot
            v-bind="{
              optionsList,
              selectedOption,
              selectOption,
            }"
          >
            <ObActionListItem
              v-for="option in optionsList"
              :key="option.label"
              :selected="option.selected"
              :disabled="option.disabled"
              @select="selectOption(option)"
            >
              {{ option.label }}
            </ObActionListItem>
          </slot>
        </ObActionList>
      </ObActionListContextProvider>
    </ObScrollableContainer>
  </ObHostedDropdown>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.button {
  @include shared.reset-button();
  display: flex;
  text-align: left;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  width: 100%;
  padding: 0 12px;
  font-family: typography.$font-family-primary;
  color: colors.$primary;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  border-radius: shared.$border-radius-s;
  min-height: 44px;
  display: flex;
  box-sizing: border-box;
  text-align: left;

  &::after {
    @include shared.coverer();
    content: '';
    border-radius: inherit;
    border: 1px solid colors.$surface-16;
    pointer-events: none;
    box-sizing: border-box;
  }
}

.value {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder {
  color: colors.$surface-40;
}

.arrow {
  color: colors.$surface-40;
  display: flex;
  font-size: 24px;
  width: 1em;
  height: 1em;
  margin-left: 12px;
  transition: transform 0.2s ease-in-out;
}

.arrowRotated {
  transform: rotate(180deg);
}

.button:focus,
.focused {
  &::after {
    border-color: #907ff5; // TODO: use token
  }
}

.invalid {
  &::after {
    border-color: colors.$status-danger;
  }
}

.disabled {
  color: colors.$surface-40;
  background-color: colors.$surface-4;
  cursor: not-allowed;
}

.sizeS {
  min-height: 32px;
}
.sizeM {
  min-height: 44px;
}
.sizeL {
  min-height: 56px;
}

.prefix {
  color: colors.$surface-40;
  margin-right: 4px;
}
</style>
