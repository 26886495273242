import type { InjectionKey } from 'vue';

export interface UiConfig {
  i18n: {
    dataTable: {
      columnsSettingsTitle: (() => string) | string;
      columnsSettingsSearchHint: (() => string) | string;
      openColumnsSettings: (() => string) | string;
      noData: (() => string) | string;
      itemsSelected: ((count: number) => string) | string;
      selectAllItems: ((count: number) => string) | string;
      clearSelection: (() => string) | string;
    };
  };
}

export const defaultUiConfig: UiConfig = {
  i18n: {
    dataTable: {
      columnsSettingsTitle: 'Select columns to display',
      columnsSettingsSearchHint: 'Search columns',
      openColumnsSettings: 'Open columns visibility settings',
      noData: 'No data',
      itemsSelected: (count) => `${count} items selected`,
      selectAllItems: (count: number) => `Select all ${count} items`,
      clearSelection: 'Clear selection',
    },
  },
};

export const UI_CONFIG_INJECTION_KEY: InjectionKey<UiConfig> = Symbol(__DEV__ ? 'UiConfig' : '');
