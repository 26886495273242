<script lang="ts" setup>
import { computed, useSlots, h, type VNodeChild, type HTMLAttributes } from 'vue';
import UiLabelIcon from './ui-label-icon.vue';
import UiLabelText from './ui-label-text.vue';
import style from './style.module.scss';

interface Props {
  asChild?: boolean;
  variant?: 'success' | 'error';
  textColor?: string;
  bgColor?: string;
}

interface SlotScope {
  rootProps: { class: HTMLAttributes['class'] };
}

const { asChild = false, variant, textColor, bgColor } = defineProps<Props>();

defineSlots<{
  default?: (props: SlotScope) => VNodeChild;
  icon?: () => VNodeChild;
}>();

const rootProps = computed<SlotScope['rootProps']>(() => {
  return {
    class: [
      style.root,
      {
        [style.variantSuccess]: variant === 'success',
        [style.variantError]: variant === 'error',
      },
    ],
    style: {
      color: textColor,
      backgroundColor: bgColor,
    },
  };
});

const slots = useSlots();

defineRender(() => {
  const content = slots.default?.({ rootProps: rootProps.value });

  if (asChild) {
    return content;
  }

  const icon = slots.icon?.();

  return h(
    'span',
    {
      ...rootProps.value,
    },
    [icon ? h(UiLabelIcon, {}, icon) : null, content ? h(UiLabelText, {}, content) : null],
  );
});
</script>
