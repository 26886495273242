<script lang="ts" setup generic="T extends GenericObject">
import type { GenericObject } from '../../../shared/types';
import type { UiDataTableColumn } from '../interfaces';
import BaseCell from './base-cell.vue';

type Column = UiDataTableColumn<T>;

interface Props {
  column?: Column;
}

const props = defineProps<Props>();
</script>

<template>
  <BaseCell :column="props.column">
    <div :class="$style.root">
      <div :class="$style.inner" />
    </div>
  </BaseCell>
</template>

<style lang="scss" module>
.root {
  padding: 6px;
  height: 40px;
  box-sizing: border-box;
  width: 100%;
}

.inner {
  position: relative;
  overflow: hidden;
  height: 100%;
  background: #f5f5f5;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;

    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
    animation: wave 1.5s linear 0.5s infinite;
  }
}

@keyframes wave {
  0% {
    transform: translate(-100%);
  }

  to {
    transform: translate(100%);
  }
}
</style>
