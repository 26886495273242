<script lang="ts" setup generic="T extends GenericObject = GenericObject">
import { computed, ref } from 'vue';
import {
  IconSortDescending,
  IconSortAscending,
  IconEyeOff,
  IconPin,
  IconPinnedOff,
  IconCaretDownFilled,
  IconCaretUpFilled,
} from '@tabler/icons-vue';
import { ObActionList, ObActionListItem } from '../../action-list';
import { ObActionMenu } from '../../action-menu';
import { ObTooltip } from '../../tooltip';
import type { GenericObject } from '../../../shared/types';
import type { UiDataTableColumn } from '../interfaces';
import { useTableContext } from '../shared';
import BaseCell from './base-cell.vue';

interface Props {
  column?: UiDataTableColumn<T>;
}

defineOptions({ inheritAttrs: false });

const props = defineProps<Props>();

const {
  pinnedColumnsKeys,
  sorting,
  hideColumn,
  startDragging,
  draggedColumnIndex,
  draggedColumnKey,
  targetColumnIndex,
  targetColumnKey,
  setSorting,
  pinColumn,
  unpinColumn,
} = useTableContext<T>();

const pinned = computed(() => props.column && pinnedColumnsKeys.value.includes(props.column.key));

function pin() {
  if (!props.column) {
    return;
  }

  pinColumn(props.column.key);
}

function unpin() {
  if (!props.column) {
    return;
  }

  unpinColumn(props.column.key);
}

function setAscendingSort() {
  if (!props.column) {
    return;
  }

  setSorting({
    sortBy: props.column.key,
    sortOrder: 'asc',
  });
}

function setDescendingSort() {
  if (!props.column) {
    return;
  }

  setSorting({
    sortBy: props.column.key,
    sortOrder: 'desc',
  });
}

let shouldResetSorting = false;

function toggleSorting() {
  if (!props.column) {
    return;
  }

  const { sortBy, sortOrder } = sorting.value;

  if (shouldResetSorting) {
    setSorting({
      sortBy: null,
      sortOrder: 'asc',
    });

    shouldResetSorting = false;

    return;
  }

  if (sortBy !== props.column.key) {
    setDescendingSort();
    return;
  }

  shouldResetSorting = true;

  if (sortOrder === 'asc') {
    setDescendingSort();
    return;
  }

  setAscendingSort();
}

const sortingActive = computed(() => props.column && sorting.value.sortBy === props.column.key);

const dropdownOpen = ref(false);

function onMouseDown(event: MouseEvent) {
  if (!props.column || props.column.frozen) {
    return;
  }

  startDragging(event, props.column.key);
}
</script>

<template>
  <ObTooltip
    placement="top"
    :activate-delay="200"
    :content="props.column?.tooltip"
    :disabled="!props.column?.tooltip"
  >
    <template #host="{ hostProps }">
      <BaseCell v-bind="$attrs" :column="props.column" @mousedown.left="onMouseDown">
        <div
          v-bind="hostProps"
          :class="[
            $style.root,
            {
              [$style.alignEnd]: props.column?.align === 'end',
              [$style.alignCenter]: props.column?.align === 'center',
              [$style.sortingActive]: sortingActive,
              [$style.dropdownOpen]: dropdownOpen,
            },
          ]"
        >
          <div :class="$style.content">
            <slot />
          </div>
          <ObActionMenu
            v-if="props.column && !props.column.frozen"
            v-model:open="dropdownOpen"
            overlay
          >
            <template #host="{ hostProps }">
              <button v-bind="hostProps" type="button" :class="$style.button" />
            </template>
            <ObActionList>
              <template v-if="props.column.sortable">
                <ObActionListItem @select="setAscendingSort()">
                  <template #leadingVisual> <IconSortAscending /> </template>
                  Sort ascending
                </ObActionListItem>
                <ObActionListItem @select="setDescendingSort()">
                  <template #leadingVisual> <IconSortDescending /> </template>
                  Sort descending
                </ObActionListItem>
              </template>
              <ObActionListItem v-if="pinned" @select="unpin()">
                <template #leadingVisual> <IconPinnedOff /> </template>
                Unpin column
              </ObActionListItem>
              <ObActionListItem v-else @select="pin()">
                <template #leadingVisual> <IconPin /> </template>
                Pin column
              </ObActionListItem>
              <ObActionListItem @select="hideColumn(props.column.key)">
                <template #leadingVisual> <IconEyeOff /> </template>
                Hide column
              </ObActionListItem>
            </ObActionList>
          </ObActionMenu>
          <div v-if="props.column?.sortable" :class="$style.controls">
            <button type="button" :class="$style.sortingButton" @click="toggleSorting()">
              <IconCaretDownFilled
                v-if="!sortingActive || sorting.sortOrder === 'desc'"
                size="16"
              />
              <IconCaretUpFilled v-else size="16" />
            </button>
          </div>
        </div>
        <div
          v-if="
            props.column &&
            props.column.key === targetColumnKey &&
            props.column.key !== draggedColumnKey
          "
          :class="[
            $style.dropIndicator,
            {
              [$style.dropIndicatorStart]: draggedColumnIndex > targetColumnIndex,
            },
          ]"
        />
      </BaseCell>
    </template>
  </ObTooltip>
</template>

<style lang="scss" module>
@use '../../../styles/colors';
@use '../../../styles/shared';
@use '../../../styles/typography';
@use '../shared' as tableSharedStyles;

.root {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex: none;
  padding: 12px;
  width: 100%;
  align-items: center;
  background: #f5f5f5;
  font-weight: 500;

  &:hover,
  &.dropdownOpen {
    background: linear-gradient(0deg, rgba(#23395d, 0.05) 0%, rgba(#23395d, 0.05) 100%), #f5f5f5;
  }
}

.content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.alignCenter {
  justify-content: center;
}

.alignEnd {
  justify-content: flex-end;
}

.button {
  @include shared.reset-button();
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: tableSharedStyles.$z-index-clickable-cel;

  &:focus-visible {
    box-shadow: inset 0 0 0 2px #3355ff;
  }
}

.button:active,
.dropdownOpen .button {
  box-shadow: inset 0 0 0 2px #7e57c2;
}

.controls {
  display: flex;
  position: relative;
  pointer-events: none;
  z-index: tableSharedStyles.$z-index-clickable-cel + 1;
}

.sortingButton {
  @include shared.reset-button();
  pointer-events: auto;
  width: 16px;
  height: 16px;
  color: #0e1a32;
  margin-left: 4px;
}

.root:not(.sortingActive) .sortingButton {
  color: #d2d3d9;
}

.dropIndicator {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #085cd9;
  pointer-events: none;
  z-index: tableSharedStyles.$z-index-drop-indicator;
}

.dropIndicatorStart {
  left: 0;
  right: auto;
}
</style>
