<script lang="ts" setup generic="T extends string | Component">
import {
  computed,
  useCssModule,
  useSlots,
  h,
  mergeProps,
  useAttrs,
  type Component,
  type VNodeChild,
} from 'vue';
import type { PolymorphicProps } from '../../shared/types';

const props = defineProps<
  PolymorphicProps<T> & {
    disabled?: boolean;
    size?: 's' | 'm' | 'l';
    variant?: 'primary' | 'secondary' | 'tertiary';
  }
>();

defineSlots<{
  default?: () => VNodeChild;
}>();

const style = useCssModule();

const rootProps = computed(() => {
  return {
    class: [
      style.root,
      {
        [style.sizeS]: props.size === 's',
        [style.sizeL]: props.size === 'l',
        [style.variantSecondary]: props.variant === 'secondary',
        [style.variantTertiary]: props.variant === 'tertiary',
        [style.disabled]: props.disabled,
      },
    ],
  };
});

const slots = useSlots();
const attrs = useAttrs();

defineRender(() => {
  const content = slots.default?.();

  if (props.as) {
    return h(props.as, mergeProps(attrs, rootProps.value, props.asProps ?? {}), content);
  }

  return h('button', { ...rootProps.value, type: 'button', disabled: props.disabled }, content);
});
</script>

<style lang="scss" module>
@use '../../styles/shared';

.root {
  @include shared.reset-button();
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 4px;
  height: 28px;
  width: 28px;
  flex: none;
  background: #7e57c2;
  color: #f5f5f5;
  border: 1px solid #7e57c2;
  padding: 4px;
  font-size: 0;

  &:hover:not(.disabled) {
    background: linear-gradient(0deg, rgba(#23395d, 0.2) 0%, rgba(#23395d, 0.2) 100%), #7e57c2;
  }

  &:focus-visible {
    outline: 1px solid #085cd9;
    outline-offset: -1px;
  }

  &.disabled {
    color: #d2d3d9;
    background: #ebecf2;
    border-color: #ebecf2;
    cursor: not-allowed;
  }

  > img,
  > svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.sizeS {
  height: 22px;
  width: 22px;
}

.sizeL {
  height: 36px;
  width: 36px;
  padding: 6px;
}

.variantSecondary,
.variantTertiary {
  color: #0e1a32;
  background: transparent;

  &:hover:not(.disabled) {
    background: #23395d0d;
  }
}

.variantSecondary {
  border-color: #d5d6d9;
}

.variantTertiary {
  border-color: transparent;

  &.disabled {
    background: transparent;
    border-color: transparent;
  }
}
</style>
