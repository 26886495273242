import { Path } from '../shared/types';
import { getValueByKey } from './get-value-by-key';

export function sortByOrder<T = unknown>(
  array: T[],
  order: unknown[],
  key?: Path<T> | ((item: T) => unknown),
) {
  const orderMap = new Map(order.map((item, index) => [item, index]));

  return array.slice().sort((a, b) => {
    const valA = key ? getValueByKey(a, key) : a;
    const valB = key ? getValueByKey(b, key) : b;

    return (orderMap.get(valA) ?? Infinity) - (orderMap.get(valB) ?? Infinity);
  });
}
