<script lang="ts" setup>
import {
  shallowRef,
  computed,
  watch,
  type VNode,
  type VNodeProps,
  type ComponentPublicInstance,
  nextTick,
  onBeforeUnmount,
} from 'vue';
import {
  useFloating,
  flip,
  autoUpdate,
  shift,
  offset,
  size,
  autoPlacement,
  type Middleware,
  type ShiftOptions,
  type OffsetOptions,
  type FlipOptions,
  type SizeOptions,
  type Placement,
  type AutoPlacementOptions,
} from '@floating-ui/vue';
import { onClickOutside, unrefElement } from '@vueuse/core';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useZIndex, useFocusScope } from '../../composables';
import { ObUnmountableTeleportWithTransition } from '../internal';

interface Props {
  active: boolean;
  overlay?: boolean;
  placement?: Placement;
  padding?:
    | number
    | Partial<{
        top: number;
        right: number;
        bottom: number;
        left: number;
      }>;
  offset?: OffsetOptions;
  shift?: boolean | ShiftOptions;
  flip?: boolean | FlipOptions;
  size?: SizeOptions;
  autoPlacement?: boolean | AutoPlacementOptions;
}

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<Props>();

const emit = defineEmits<{
  clickOutside: [event: PointerEvent];
}>();

defineSlots<{
  default?: () => VNode;
  host?: (props: {
    active: boolean;
    hostProps: VNodeProps & { ref: (el: Element | ComponentPublicInstance | null) => void };
  }) => VNode;
}>();

const active = defineModel<boolean>('active', { default: false });

const { zIndex } = useZIndex({ active, size: 2 });

const hostRef = shallowRef();
const containerRef = shallowRef<HTMLDivElement | null>(null);

// TODO:
// support middleware
// https://floating-ui.com/docs/hide
// https://floating-ui.com/docs/arrow
// https://floating-ui.com/docs/inline

const middleware = computed<Middleware[]>(() => {
  const result: Middleware[] = [];

  if (props.offset) {
    result.push(offset(props.offset));
  }

  if (props.shift) {
    result.push(
      shift({
        padding: props.padding,
        ...(typeof props.shift === 'object' ? props.shift : {}),
      }),
    );
  }

  if (props.flip) {
    result.push(
      flip({
        padding: props.padding,
        ...(typeof props.flip === 'object' ? props.flip : {}),
      }),
    );
  } else if (props.autoPlacement) {
    result.push(
      autoPlacement({
        padding: props.padding,
        ...(typeof props.autoPlacement === 'object' ? props.autoPlacement : {}),
      }),
    );
  }

  if (props.size) {
    result.push(size({ padding: props.padding, ...props.size }));
  }

  return result;
});

const placement = computed(() => props.placement || 'bottom-start');

const { floatingStyles } = useFloating(hostRef, containerRef, {
  placement,
  middleware,
  whileElementsMounted: autoUpdate,
  open: active,
  transform: false,
});

onClickOutside(
  containerRef,
  (event: PointerEvent) => {
    emit('clickOutside', event);
  },
  { ignore: [hostRef] },
);

useFocusScope(containerRef);

const hostProps = computed(() => {
  return {
    ref: (el: Element | ComponentPublicInstance | null) => {
      hostRef.value = unrefElement(el as any); // TODO: how not to cast no any? Element vs HTMLElement
    },
  };
});

defineExpose({
  $el: containerRef,
});

function lockScroll() {
  if (containerRef.value) {
    disableBodyScroll(containerRef.value, {
      reserveScrollBarGap: true,
    });
  }
}

function unlockScroll() {
  if (containerRef.value) {
    enableBodyScroll(containerRef.value);
  }
}

watch(
  () => active,
  () => {
    if (!props.overlay) {
      return;
    }

    if (!active.value) {
      unlockScroll();
      return;
    }

    nextTick(() => {
      if (active.value) {
        lockScroll();
      }
    });
  },
);

onBeforeUnmount(() => {
  unlockScroll();
});
</script>

<template>
  <slot name="host" v-bind="{ hostProps, active }" />
  <Teleport v-if="overlay && active" to="body" defer>
    <div :class="$style.overlay" :style="{ zIndex }" />
  </Teleport>
  <ObUnmountableTeleportWithTransition
    :active
    :enter-from-class="$style.enterFrom"
    :enter-active-class="$style.enterActive"
    :leave-active-class="$style.leaveActive"
    :leave-to-class="$style.leaveTo"
  >
    <div
      v-if="active"
      ref="containerRef"
      :style="{ ...floatingStyles, zIndex: zIndex! + 1 }"
      :class="$style.container"
      v-bind="$attrs"
    >
      <slot />
    </div>
  </ObUnmountableTeleportWithTransition>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';

.enterActive,
.leaveActive {
  transition-property: opacity, transform;
  transition-duration: 0.2s;
}

.enterActive {
  transition-timing-function: ease-out;
}

.leaveActive {
  transition-timing-function: ease-in;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: scale(0.9);
}

.container {
  box-sizing: border-box;
  max-height: 480px;
  width: auto;
  border-radius: 12px;
  background: #fff;
  color: colors.$primary;
  border: 1px solid colors.$surface-6;
  box-shadow: 0px 0px 18px 0px rgba(2, 17, 72, 0.2);
  font-family: typography.$font-family-primary;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
